import {useNavigate} from "react-router-dom";
import {useAuth} from "./Auth";
import React, {useEffect, useRef} from "react";
import {CenterBox, FixedBox, FullViewport} from "./Misc";
import Title from "./Title";
import {SinglelineCopyright} from "./Copyright";


function DoLogoff({children}) {
    const navigate = useNavigate();
    const {handleLogout} = useAuth();
    const isOneShot = useRef(false);
    const handleClick = (e) => {
        e.preventDefault();
        navigate('/login');
    };

    useEffect(() => {
        if (!isOneShot.current) {
            isOneShot.current = true;
            handleLogout();
        }
    }, [handleLogout]);
    return (<>
        <FixedBox>
            <Title vertical={false}/>
        </FixedBox>
        <FullViewport>
            <CenterBox $width={400} style={{color: "black"}}>
                {children}
                <button className="uk-button uk-button-primary" onClick={handleClick}>Вернуться</button>
            </CenterBox>
            <SinglelineCopyright/>
        </FullViewport>
    </>);
}


function Logoff() {
    return (<>
        <DoLogoff>
            <div className="uk-margin">
                Сеанс работы был завершен пользователем.
            </div>
        </DoLogoff>
    </>);
}


function Timeout() {
    return (<>
        <DoLogoff>
            <div className="uk-margin">
                Вы слишком долго бездействовали. В целях обеспечения безопасности сессия была
                принудительно завершена.
            </div>
            <div className="uk-margin">
                Если вы хотите продолжить работу, войдите в личный кабинет снова.
            </div>
        </DoLogoff>
    </>);
}


function Lifetime() {
    return (<>
        <DoLogoff>
            <div className="uk-margin">
                В целях обеспечения безопасности сеанс работы был принудительно завершен из-за превышения
                максимально допустимого времени сессии.
            </div>
            <div className="uk-margin">
                Если вы хотите продолжить работу, войдите в личный кабинет снова.
            </div>
        </DoLogoff>
    </>);
}


export {Logoff, Timeout, Lifetime};


/// eof ///
