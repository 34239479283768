import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getAccessToken} from "./Auth";
import {apiTransport} from "./api";
import {getStorageValue, setStorageValue} from "./storage";
import {TRANSPORT_KEY_NAME} from "./Transport";
import {Spinner, Rubles} from "./Misc";


export default function Finance() {
    const navigate = useNavigate();
    const isLoading = useRef(false);
    const [loading, setLoading] = useState(true);

    let transport = getStorageValue(TRANSPORT_KEY_NAME);

    useEffect(() => {
        console.log(`Finance.useEffect(): transport = ${JSON.stringify(transport)}}`);
        if (transport) {
            setLoading(false);
            return;
        }
        if (!isLoading.current) {
            isLoading.current = true;

            const accessToken = getAccessToken();

            console.log(`Finance.useEffect(): token = ${accessToken}`);
            apiTransport(accessToken).then((result) => {
                const response = result.data;

                console.log(`Finance.useEffect(): result = ${JSON.stringify(response)}`);
                setStorageValue(TRANSPORT_KEY_NAME, response);
                setLoading(false);
            }).catch((e) => {
                console.log(`Finance.useEffect(): e = ${e}`);
                navigate('/error');
            });
        }
    }, []);

    function composeRates(vehicles, rates) {
        if (!vehicles || !rates) {
            return {};
        }

        let result = {};
        let list = [];

        vehicles.forEach(item => {
            const key = item.rate;

            if (key in result) {
                result[key].count += 1;
                result[key].vehicles.push({
                    id: item.id,
                    model: item.model,
                    license: item.license
                });
            } else {
                result[key] = {
                    count: 1,
                    rate: rates[key].cost,
                    title: rates[key].title,
                    mno: rates[key].mno ?? 0,
                    vehicles: [{id: item.id, model: item.model, license: item.license}]
                };
            }
        });
        Object.keys(result).forEach(key => {
            result[key].id = key;
            list.push(result[key]);
        });
        return list;
    }

    function calcOverallCost(rates) {
        if (!Object.keys(rates).length) {
            return 0;
        }

        let sum = {cost: 0, mno: 0, total: 0};

        rates.forEach(item => {
            sum.total += (item.rate + item.mno) * item.count;
            sum.cost += item.rate * item.count;
            sum.mno += item.mno * item.count;
        });
        return sum;
    }

    const rates = composeRates(transport?.vehicles, transport?.rates);
    const overall = calcOverallCost(rates);

    console.log(`${JSON.stringify(rates)}`);
    return (<>
        <div style={{display: "block"}}>
            {!loading &&
                <div style={{display: "block"}}>
                    <div style={{display: "block", width: "980px"}}>
                        <h5 className={"uk-heading-line"}>
                            <span style={{fontFamily: "Manrope"}}><b>Детализация абонентской платы</b></span>
                        </h5>
                        <div>
                            <table className="uk-table uk-table-striped uk-table-hover uk-table-small">
                                <thead>
                                <tr>
                                    <th><b>Тариф</b></th>
                                    <th style={{width: "150px"}}><b>Стоимость</b></th>
                                    <th style={{width: "150px"}}><b>Услуги связи</b></th>
                                    <th style={{width: "150px"}}><b>ТС на тарифе</b></th>
                                    <th style={{width: "150px"}}><b>Сумма</b></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    rates.map(item => {
                                        return (<tr key={item.id}>
                                            <td>
                                                {item.title}
                                                <div className="uk-inline" style={{verticalAlign: "baseline"}}>
                                                    <span
                                                        className="uk-margin-small-left"
                                                        data-uk-icon="icon: question; ratio: 0.7"></span>
                                                    <div className="uk-card-small uk-card-body uk-card-default"
                                                         uk-drop="mode: hover; duration: 0; delay-hide: 0">
                                                        <b style={{color: "black"}}>Состав тарифа:</b><br/>
                                                        <hr/>
                                                        <span data-uk-icon="icon: check; ratio: 0.7"
                                                              className="uk-margin-small-right"></span>
                                                        Подсчёт пассажиров
                                                        <br/>
                                                        <span data-uk-icon="icon: check; ratio: 0.7"
                                                              className="uk-margin-small-right"></span>
                                                        Фиксация GPS
                                                        <br/>
                                                        <span data-uk-icon="icon: check; ratio: 0.7"
                                                              className="uk-margin-small-right"></span>
                                                        Живое видео
                                                        <br/>
                                                        <span data-uk-icon="icon: check; ratio: 0.7"
                                                              className="uk-margin-small-right"></span>
                                                        Хранение видеоданных
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{item.rate}&nbsp;&#8381;</td>
                                            <td><Rubles value={item.mno}/></td>
                                            <td>
                                                {item.count}
                                                <div className="uk-inline" style={{verticalAlign: "baseline"}}>
                                                    <span className="uk-margin-small-left"
                                                          data-uk-icon="icon: question; ratio: 0.7"></span>
                                                    <div className="uk-card-small uk-card-body uk-card-default"
                                                         uk-drop="mode: hover; duration: 0; delay-hide: 0">
                                                        <b style={{color: "black"}}>Список ТС на тарифе:</b><br/>
                                                        <hr/>
                                                        <ul>
                                                            {
                                                                item.vehicles.map((car, k) => {
                                                                    return (
                                                                        <li key={k}>{car.model} ({car.license})</li>)
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{(item.rate + item.mno) * item.count}&nbsp;&#8381;</td>
                                        </tr>);
                                    })
                                }
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}><b>Итого:</b></td>
                                    <td>
                                        <b>{overall.total}&nbsp;&#8381;</b>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            {!!overall.mno &&
                                <div className={'uk-alert uk-alert-primary'}>
                                    Стоимость сервиса <b>{overall.cost}&nbsp;&#8381;</b> и услуг связи <b>{overall.mno}&nbsp;&#8381;</b>.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {loading && <Spinner/>}
        </div>
    </>);
}


/// eof ///
