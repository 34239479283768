import React, {useEffect, useRef, useState} from "react";
import {apiDeleteRoute, apiRoutes, apiUpdateRoute} from './api';
import {getAccessToken} from "./Auth";
import {useNavigate} from "react-router-dom";
import {Phone, Sales, Spinner} from "./Misc";
import {getStorageValue, setStorageValue} from "./storage";
import UIkit from "uikit";
import {PROFILE_KEY_NAME, SUMMARY_KEY_NAME} from "./Profile";


const ROUTES_KEY_NAME = "routes";


export default function AppRoutes() {
    const navigate = useNavigate();
    const isLoading = useRef(false);
    const [loading, setLoading] = useState(true);
    const [filterValue, setFilterValue] = useState('');
    const uiDialogRef = useRef(null);
    let data = getStorageValue(ROUTES_KEY_NAME);

    useEffect(() => {
        console.log(`AppRoutes.useEffect(): data = ${JSON.stringify(data)}`);
        if (data) {
            setLoading(false);
            return;
        }
        if (!isLoading.current) {
            isLoading.current = true;

            const accessToken = getAccessToken();

            console.log(`AppRoutes.useEffect(): token = ${accessToken}`);
            apiRoutes(accessToken).then((result) => {
                const response = result.data;

                setLoading(false);
                console.log(`AppRoutes.useEffect(): result = ${JSON.stringify(response)}`);
                setStorageValue(ROUTES_KEY_NAME, response);
                isLoading.current = false;
            }).catch((e) => {
                console.log(`AppRoutes.useEffect(): e = ${e}`);
                isLoading.current = false;
                navigate('/error');
            });
        }
    }, []);

    const emptyDialogState = {id: -1, title: "", cost: 0, comment: "", vehicles: []};
    const [dialogState, setDialogState] = useState(emptyDialogState);
    const uiDialogRouteRef = useRef(null);
    const uiDialogCostRef = useRef(null);
    const uiDialogCommentRef = useRef(null);

    const getDialogData = (element) => {
        return {
            id: element.getAttribute('data-index'),
            title: uiDialogRouteRef.current.value,
            cost: parseInt(uiDialogCostRef.current.value),
            comment: uiDialogCommentRef.current.value
        };
    }

    function updateRoute(obj) {
        const accessToken = getAccessToken();

        apiUpdateRoute(accessToken, obj).then((result) => {
            const response = result.data;

            console.log(`updateRoute(): result [1] = ${JSON.stringify(response)}`);
            setLoading(true);
            isLoading.current = true;
            apiRoutes(accessToken).then((result) => {
                const response = result.data;

                setLoading(false);
                console.log(`updateRoute(): result [2] = ${JSON.stringify(response)}`);
                setStorageValue(ROUTES_KEY_NAME, response);
                data = response;
                isLoading.current = false;

                const profile = getStorageValue(PROFILE_KEY_NAME);

                profile.customer.routes = data.routes.length;
                setStorageValue(PROFILE_KEY_NAME, profile);
                setStorageValue(SUMMARY_KEY_NAME, null);
            });
        }).catch((e) => {
            isLoading.current = false;
            UIkit.notification("Не удалось обновить маршрут...", {status: 'danger'})
        });
    }

    function deleteRoute(id) {
        const accessToken = getAccessToken();

        apiDeleteRoute(accessToken, id).then((result) => {
            const response = result.data;

            console.log(`deleteRoute(): result [1] = ${JSON.stringify(response)}`);
            setLoading(true);
            isLoading.current = true;
            apiRoutes(accessToken).then((result) => {
                const response = result.data;

                setLoading(false);
                console.log(`deleteRoute(): result [2] = ${JSON.stringify(response)}`);
                setStorageValue(ROUTES_KEY_NAME, response);
                data = response;
                isLoading.current = false;

                const profile = getStorageValue(PROFILE_KEY_NAME);

                profile.customer.routes = data.routes.length;
                setStorageValue(PROFILE_KEY_NAME, profile);
                setStorageValue(SUMMARY_KEY_NAME, null);
            });
        }).catch((e) => {
            isLoading.current = false;
            UIkit.notification("Не удалось удалить маршрут...", {status: 'danger'})
        });
    }

    function createListener(e) {
        if (e.target.id === "createButton") {
            e.preventDefault();

            const element = uiDialogRef.current;
            const obj = getDialogData(element);

            element.removeEventListener("click", createListener);
            console.log(`createRouteButton(): id=${obj.id}, route=${obj.title}, cost=${obj.cost}, comment='${obj.comment}'`);
            updateRoute(obj);
        } else if (e.target.id === "cancelButton") {
            e.preventDefault();

            const element = uiDialogRef.current;

            element.removeEventListener("click", createListener);
        }
    }

    function editDeleteListener(e) {
        if (e.target.id === "createButton") {
            e.preventDefault();

            const element = uiDialogRef.current;
            const obj = getDialogData(element);

            element.removeEventListener("click", editDeleteListener);
            console.log(`editRouteButton(): id=${obj.id}, route=${obj.title}, cost=${obj.cost}, comment='${obj.comment}'`);
            updateRoute(obj);
        } else if (e.target.id === "cancelButton") {
            e.preventDefault();

            const element = uiDialogRef.current;

            element.removeEventListener("click", editDeleteListener);
        } else if (e.target.id === "deleteButton") {
            e.preventDefault();

            const element = uiDialogRef.current;
            const obj = getDialogData(element);

            element.removeEventListener("click", editDeleteListener);
            console.log(`deleteRouteButton(): index=${obj.id}`);
            deleteRoute(obj.id);
        }
    }

    function createRouteDialog(e) {
        e.preventDefault();

        const element = uiDialogRef.current;

        element.addEventListener("click", createListener);
        console.log(`createRouteDialog() called`);
        setDialogState(emptyDialogState);
        UIkit.modal(element).show();
    }

    function editDeleteRouteDialog(e, id) {
        e.preventDefault();

        const element = uiDialogRef.current;
        const item = data.routes.find(item => (id === item.id));

        setDialogState({
            id: item.id,
            title: item.title,
            cost: item.cost,
            comment: item.comment,
            vehicles: item.vehicles
        });
        element.addEventListener("click", editDeleteListener);
        UIkit.modal(element).show();
    }

    return (<>
        <div style={{display: "block"}}>
            {!loading &&
                <div style={{width: "980px"}}>
                    <h5 className={"uk-heading-line"}>
                        <span style={{fontFamily: "Manrope"}}><b>Маршруты парка</b></span>
                    </h5>
                    {data?.routes?.length > 0 &&
                        <div>
                            <div style={{width: "100%"}}>
                                <div style={{display: "inline-block", width: "620px"}}>
                                    <form className="uk-search uk-search-default" style={{width: "100%"}}>
                                        <span data-uk-search-icon></span>
                                        <input className="uk-search-input"
                                               type="search"
                                               placeholder="Фильтр по номеру маршрута и заметке..."
                                               aria-label=""
                                               maxLength="128"
                                               onChange={e => setFilterValue(e.target.value.toLowerCase())}></input>
                                    </form>
                                </div>
                                <div className="uk-margin-left" style={{
                                    display: "inline-block",
                                    width: "340px",
                                    height: "100%",
                                    verticalAlign: "middle",
                                    textAlign: "right"
                                }}>
                                    Всего маршрутов: <b>{data.routes.length}</b>
                                </div>
                            </div>
                            <div style={{width: "980px"}}>
                                <table className="uk-table uk-table-striped uk-table-hover uk-table-small"
                                       style={{fontSize: "13px"}}>
                                    <thead>
                                    <tr>
                                        <th style={{width: "200px", verticalAlign: "middle"}}><b>Маршрут</b></th>
                                        <th style={{width: "200px", verticalAlign: "middle"}}><b>Стоимость проезда</b>
                                        </th>
                                        <th style={{width: "225px", verticalAlign: "middle"}}><b>ТС</b></th>
                                        <th style={{verticalAlign: "middle"}}><b>Заметка</b></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data?.routes?.filter(item => {
                                            if (!filterValue) {
                                                return true;
                                            }
                                            return item?.title?.toLowerCase().includes(filterValue) ||
                                                item?.comment?.toLowerCase().includes(filterValue);
                                        }).map(item => {
                                            return (
                                                <tr key={item.id} onClick={(e) => editDeleteRouteDialog(e, item.id)}>
                                                    <td style={{cursor: "pointer"}}>{item?.title}</td>
                                                    <td style={{cursor: "pointer"}}>{item?.cost}&nbsp;&#8381;</td>
                                                    <td>{item?.vehicles.map((s, k) => {
                                                        return (<span key={k}>{s}<br/></span>);
                                                    })}</td>
                                                    <td style={{cursor: "pointer"}}>{item?.comment || "-"}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <button type="button" className="uk-button uk-button-primary" onClick={createRouteDialog}>
                                Создать
                            </button>
                            <div className={'uk-alert uk-alert-danger'} style={{fontSize: "14px"}}>
                                Обращаем Ваше внимание, что изменение стоимости проезда на маршруте приведет к изменению
                                в расчетной выручке в отчетах как за текущий, так и за прошедшие периоды времени.
                            </div>
                        </div>
                    }
                    {data?.routes?.length < 1 &&
                        <div>
                            <div className={'uk-alert uk-alert-primary'}>
                                <b>Не задано ни одного маршрута.</b>
                            </div>
                            <button type="button" className="uk-button uk-button-primary" onClick={createRouteDialog}>
                                Создать
                            </button>
                            <hr/>
                            <div style={{width: "980px", fontSize: "16px"}} className={'uk-margin-bottom'}>
                                <div>
                                    В данном разделе Вы можете воспользоваться Смарт-опцией «<b>Маршруты</b>».
                                </div>
                                <div style={{margin: "12px 0 0 0"}}>
                                    Данная опция позволяет формировать умные отчеты по пассажирскому трафику Вашего
                                    парка с разделением по маршрутам. В любой момент времени всего в несколько кликов,
                                    Вы сможете сформировать отчет, на основании которого можно получать
                                    следующую информацию:
                                    <ul>
                                        <li style={{margin: "12px 0 0 0"}}>
                                            Оценка загруженности любого маршрута за интересующий Вас интервал времени,
                                            по дням, неделям и месяцам.
                                        </li>
                                        <li style={{margin: "12px 0 0 0"}}>
                                            Сколько выручки приносит тот или иной маршрут за выбранный Вами интервал
                                            времени, в том числе в зависимости от сезонности.
                                        </li>
                                        <li style={{margin: "12px 0 0 0"}}>
                                            Какие маршруты наиболее нагружены пассажирами, а каким наоборот, свойственен
                                            небольшой пассажиропоток.
                                        </li>
                                    </ul>
                                </div>
                                <div style={{margin: "12px 0 0 0"}}>
                                    Смарт-опция «<b>Маршруты</b>» доступна на тарифе «<b>Стандарт</b>».
                                </div>
                                <hr/>
                                <div style={{margin: "12px 0 0 0"}}>
                                    Задать интересующие Вас вопросы, а также оставить заявку на подключение опции Вы
                                    можете по телефону <Phone/>, либо по электронной почте <Sales/>.
                                </div>
                            </div>
                        </div>
                    }
                    <div>
                        <div ref={uiDialogRef} data-index={dialogState.id} className="uk-flex-top" data-uk-modal>
                            <div className={"uk-modal-dialog uk-margin-auto-vertical"}>
                                <div className={"uk-modal-body"}>
                                    <form className="uk-form-horizontal">
                                        <div className="uk-margin">
                                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                                Номер маршрута:
                                            </label>
                                            <div className="uk-form-controls">
                                                <input autoFocus required className="uk-input" ref={uiDialogRouteRef}
                                                       style={{color: "black", fontSize: "15px"}} type="text"
                                                       value={dialogState.title || ""}
                                                       onChange={(e) => {}}/>
                                            </div>
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                                Стоимость проезда:
                                            </label>
                                            <div className="uk-form-controls">
                                                <input required pattern={"\d+"} className="uk-input"
                                                       ref={uiDialogCostRef}
                                                       style={{color: "black", fontSize: "15px"}}
                                                       type="number" value={dialogState.cost || "0"}
                                                       onChange={(e) => {}}/>
                                            </div>
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                                Транспортные средства:
                                            </label>
                                            <div className="uk-form-controls">
                                                <input className="uk-input uk-disabled uk-text-truncate"
                                                       style={{color: "black", fontSize: "15px"}} type="text"
                                                       disabled value={dialogState.vehicles?.join(", ")}/>
                                            </div>
                                        </div>
                                        <div className={'uk-alert uk-alert-primary'} style={{fontSize: "14px"}}>
                                            Назначение транспортных средств на маршрут выполняется в
                                            разделе <b>Транспорт</b>.
                                        </div>
                                        {(dialogState.vehicles.length > 0) &&
                                            <div className={'uk-margin-top'}>
                                                <div className={'uk-alert uk-alert-danger'} style={{fontSize: "14px"}}>
                                                    Операция удаления для этого маршрута невозможна, пока на него
                                                    назначено хотя бы одно транспортное средство.
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            <label className="uk-form-label" htmlFor="form-stacked-text">
                                                Заметка:
                                            </label>
                                            <div className="uk-form-controls">
                                                <input className="uk-input" ref={uiDialogCommentRef}
                                                       style={{color: "black", fontSize: "15px"}}
                                                       type="text" value={dialogState.comment || ""}
                                                       onChange={(e) => {}}/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="uk-text-right uk-modal-footer">
                                    {dialogState?.id !== -1 &&
                                        <button
                                            className="uk-button uk-button-danger uk-modal-close uk-margin-right"
                                            type="button" id="deleteButton" disabled={dialogState.vehicles.length > 0}>
                                            Удалить
                                        </button>
                                    }
                                    <button
                                        className="uk-button uk-button-default uk-modal-close uk-margin-right"
                                        type="button" id="cancelButton">
                                        Отмена
                                    </button>
                                    <button className="uk-button uk-button-primary uk-modal-close"
                                            type="button" id="createButton">
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && <Spinner/>}
        </div>
    </>);
}


export {ROUTES_KEY_NAME};


/// eof ///
