import React, {useEffect, useRef, useState} from "react";
import {apiRoutes, apiTransport, apiUpdateVehicle} from './api';
import {getAccessToken} from "./Auth";
import {useNavigate} from "react-router-dom";
import {Phone, Spinner, Support, Pager, TableHead, calcChunkCount} from "./Misc";
import {getStorageValue, setStorageValue} from "./storage";
import UIkit from "uikit";
import {ROUTES_KEY_NAME} from "./AppRoutes";
import {SUMMARY_KEY_NAME} from "./Profile";


const TRANSPORT_KEY_NAME = "transport";


export default function Transport() {
    const navigate = useNavigate();
    const isLoading = useRef(false);
    const [loading, setLoading] = useState(true);
    const [filterValue, setFilterValue] = useState('');

    let data = getStorageValue(TRANSPORT_KEY_NAME);
    let routes = getStorageValue(ROUTES_KEY_NAME);

    useEffect(() => {
        // console.log(`Transport.useEffect(): data = ${JSON.stringify(data)}`);
        if (data && routes) {
            setLoading(false);
            return;
        }
        if (!isLoading.current) {
            isLoading.current = true;

            const accessToken = getAccessToken();

            console.log(`Transport.useEffect(): token = ${accessToken}`);
            apiTransport(accessToken).then((result) => {
                const response = result.data;

                console.log(`Transport.useEffect(): result [1] = ${JSON.stringify(response)}`);
                setStorageValue(TRANSPORT_KEY_NAME, response);
                if (!routes) {
                    apiRoutes(accessToken).then((result) => {
                        const response = result.data;

                        console.log(`Transport.useEffect(): result [2] = ${JSON.stringify(response)}`);
                        setStorageValue(ROUTES_KEY_NAME, response);
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                }
            }).catch((e) => {
                console.log(`Transport.useEffect(): e = ${e}`);
                navigate('/error');
            });
        }
    }, []);

    const uiDialogRef = useRef(null);
    const [dialogState, setDialogState] = useState({id: 0, model: "", license: "", route: -1, comment: ""});
    const uiDialogModelRef = useRef(null);
    const uiDialogRouteRef = useRef(null);
    const uiDialogCommentRef = useRef(null);

    const getDialogData = (element) => {
        return {
            id: element.getAttribute('data-index'),
            model: uiDialogModelRef.current.value,
            route: uiDialogRouteRef.current.value,
            comment: uiDialogCommentRef.current.value
        };
    }

    function updateVehicle(obj) {
        const accessToken = getAccessToken();

        apiUpdateVehicle(accessToken, obj).then((result) => {
            const response = result.data;

            console.log(`updateVehicle(): result [1] = ${JSON.stringify(response)}`);
            setLoading(true);
            isLoading.current = true;
            apiTransport(accessToken).then((result) => {
                const response = result.data;

                setLoading(false);
                console.log(`updateVehicle(): result [2] = ${JSON.stringify(response)}`);
                setStorageValue(TRANSPORT_KEY_NAME, response);
                data = response;
                isLoading.current = false;
            });
            apiRoutes(accessToken).then((result) => {
                const response = result.data;

                console.log(`updateVehicle(): result [3] = ${JSON.stringify(response)}`);
                setStorageValue(ROUTES_KEY_NAME, response);
            });
            setStorageValue(SUMMARY_KEY_NAME, null);
        }).catch((e) => {
            isLoading.current = false;
            UIkit.notification("Не удалось обновить ТС...", {status: 'danger'})
        });
    }

    function editVehicleListener(e) {
        if (e.target.id === "saveButton") {
            e.preventDefault();

            const element = uiDialogRef.current;
            const obj = getDialogData(element);

            element.removeEventListener("click", editVehicleListener);
            console.log(`editVehicleListener(): id=${obj.id}, model=${obj.model}, route=${obj.route}, comment='${obj.comment}'`);
            updateVehicle(obj);
        } else if (e.target.id === "cancelButton") {
            e.preventDefault();

            const element = uiDialogRef.current;

            element.removeEventListener("click", editVehicleListener);
        }
    }

    function editVehicleDialog(e, id) {
        e.preventDefault();

        const element = uiDialogRef.current;
        const item = data.vehicles.find(item => (id === item.id));

        setDialogState({id: item.id, model: item.model, license: item.license, route: item.route, comment: item.comment});
        element.addEventListener("click", editVehicleListener);
        UIkit.modal(element).show();
        uiDialogModelRef.current.autofocus = true;
    }

    const RateDescription = ({obj}) => {
        if (!obj) {
            return (<span>-</span>);
        }
        return (<span>{obj.title} ({obj.cost}&nbsp;&#8381;)</span>);
    }

    const actualVehicles = data?.vehicles?.filter(item => {
        if (!filterValue) {
            return true;
        }
        return item?.model.toLowerCase().includes(filterValue) ||
            item?.license?.toLowerCase().includes(filterValue) ||
            item?.driver?.toLowerCase().includes(filterValue) ||
            routes?.routes?.find(v => (v.id === item?.route))?.title.toLowerCase().includes(filterValue) ||
            item?.rate[1]?.toLowerCase().includes(filterValue) ||
            item?.comment?.toLowerCase().includes(filterValue);
    }) || [];

    const itemPerPage = 10;
    const totalPages = calcChunkCount(actualVehicles, itemPerPage);
    const [curPage, setCurPage] = useState(0);

    console.log(`totalPages = ${totalPages}, len = ${actualVehicles.length}`);
    return (<>
        <div style={{display: "block"}}>
            {!loading &&
                <div>
                    <div style={{width: "980px"}}>
                        <h5 className={"uk-heading-line"}>
                            <span style={{fontFamily: "Manrope"}}><b>Транспортные средства парка</b></span>
                        </h5>
                        <div style={{width: "100%"}}>
                            <div style={{display: "inline-block", width: "620px"}}>
                                <form className="uk-search uk-search-default" style={{width: "100%"}}>
                                    <span data-uk-search-icon></span>
                                    <input className="uk-search-input"
                                           type="search"
                                           placeholder="Фильтр по ТС, госномеру, водителю, маршруту, тарифу и заметке..."
                                           maxLength="128"
                                           onChange={e => {
                                               setFilterValue(e.target.value.toLowerCase());
                                               setCurPage(0);
                                           }}></input>
                                </form>
                            </div>
                            <div className="uk-margin-left" style={{
                                display: "inline-block",
                                width: "340px",
                                height: "100%",
                                verticalAlign: "middle",
                                textAlign: "right"
                            }}>
                                Всего ТС: <b>{data?.vehicles?.length || "-"}</b>
                            </div>
                        </div>
                        <div>
                            <table className="uk-table uk-table-striped uk-table-hover uk-table-small"
                                   style={{fontSize: "13px"}}>
                                <thead>
                                <tr>
                                    <th style={{width: "135px"}}><TableHead>ТС</TableHead></th>
                                    <th style={{width: "100px"}}>
                                        <div
                                            uk-tooltip="title: Для фильтрации по ГРЗ вводите латинские буквы.; pos: right">
                                            <TableHead>ГРЗ</TableHead>
                                        </div>
                                    </th>
                                    <th style={{width: "160px"}}><TableHead>Маршрут</TableHead></th>
                                    <th style={{width: "160px"}}><TableHead>Водитель</TableHead></th>
                                    <th style={{width: "125px"}}><TableHead>Тариф</TableHead></th>
                                    <th style={{width: "220px"}}><TableHead>Заметка</TableHead></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    actualVehicles
                                        .slice(curPage * itemPerPage, (curPage + 1) * itemPerPage)
                                        .map(item => {
                                        return (
                                            <tr key={item?.id} onClick={(e) => editVehicleDialog(e, item.id)}>
                                                <td style={{cursor: "pointer"}}>{item?.model}</td>
                                                <td>{item?.license}</td>
                                                <td style={{cursor: "pointer"}}><RateDescription obj={routes?.routes.find(v => (v.id === item?.route))}/></td>
                                                <td>{item?.driver || "-"}</td>
                                                <td>{data?.rates[item?.rate]?.title || "-"}</td>
                                                <td style={{cursor: "pointer"}}>{item?.comment || "-"}</td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>
                            </table>
                            {(data?.vehicles?.length > itemPerPage) &&
                                <div>
                                    <Pager curPage={curPage} totalPages={totalPages} setCurPage={setCurPage} />
                                </div>
                            }
                            <div className={'uk-alert uk-alert-primary uk-margin-small-bottom'}>
                                Кликнув по строке ТС можно отредактировать его наименование, задать маршрут или снять
                                с маршрута, и при необходимости добавить заметку.
                            </div>
                            <div className={'uk-alert uk-alert-primary uk-margin-small-top'}>
                                Добавить новое транспортное средство можно через персонального менеджера или техническую
                                поддержку по телефону <Phone/> или электронной почте <Support/>.
                            </div>
                        </div>
                    </div>
                    <div ref={uiDialogRef} data-index={dialogState.id} className="uk-flex-top" data-uk-modal>
                        <div className={"uk-modal-dialog uk-margin-auto-vertical"}>
                            <div className={"uk-modal-body"}>
                                <form className="uk-form-horizontal">
                                    <div className="uk-margin">
                                        <label className="uk-form-label" htmlFor="form-stacked-text">
                                            Модель:
                                        </label>
                                        <div className="uk-form-controls">
                                            <input required className="uk-input" ref={uiDialogModelRef}
                                                   style={{color: "black", fontSize: "15px"}} type="text"
                                                   value={dialogState.model || ""} maxLength={255}
                                                   onChange={(e) => {}}/>
                                        </div>
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label" htmlFor="form-stacked-text">ГРЗ:</label>
                                        <div className="uk-form-controls">
                                            <input className="uk-input uk-disabled"
                                                   style={{color: "black", fontSize: "15px"}} type="text"
                                                   value={dialogState.license}
                                                   disabled/>
                                        </div>
                                    </div>
                                    <div className={'uk-alert uk-alert-primary'}
                                         style={{fontSize: "14px"}}>Изменение ГРЗ транспортного средства возможно через техническую поддержку.
                                    </div>
                                    <div className="uk-margin">
                                        <label className="uk-form-label" htmlFor="form-stacked-text">Маршрут:</label>
                                        <div className="uk-form-controls">
                                            <select className="uk-select" style={{color: "black", fontSize: "15px"}} ref={uiDialogRouteRef}>
                                                <option selected={!routes?.routes.find(v => (dialogState.route === v.id))} value="-1">Без маршрута</option>
                                                {
                                                    routes?.routes.map((item => {
                                                        return (<option key={item.id} selected={item.id === dialogState.route} value={item.id}>{item.title}</option>);
                                                    }))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="uk-form-label"
                                               htmlFor="form-stacked-text">Заметка:</label>
                                        <div className="uk-form-controls">
                                            <input className="uk-input" ref={uiDialogCommentRef}
                                                   style={{color: "black", fontSize: "15px"}}
                                                   type="text" value={dialogState.comment || ""} maxLength={255}
                                                   onChange={(e) => {}}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="uk-text-right uk-modal-footer">
                                <button
                                    className="uk-button uk-button-default uk-modal-close uk-margin-right"
                                    type="button" id="cancelButton">
                                    Отмена
                                </button>
                                <button
                                    className="uk-button uk-button-primary uk-modal-close"
                                    type="button" id="saveButton">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && <Spinner/>}
        </div>
    </>);
}


export {TRANSPORT_KEY_NAME};


/// eof ///
