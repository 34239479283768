import {useNavigate} from "react-router-dom";
import React from "react";
import {apiProfile} from "./api";
import {getAccessToken} from "./Auth";


export default function Dashboard() {
    const navigate = useNavigate();
    const handleClick = (e) => {
        e.preventDefault();
        navigate('/error');
    };

    const handleKeepAlive = (e) => {
        const accessToken = getAccessToken();

        console.log(`handleKeepAlive(): called`);
        apiProfile(accessToken).then((result) => {
            const response = result.data;

            console.log(`handleKeepAlive(): result = ${JSON.stringify(response)}`);
        }).catch((e) => {
            navigate('/error');
        });
    };

    return (<>
        <div>
            <div style={{display: "block", width: "980px"}}>
                <h5 className={"uk-heading-line"}>
                    <span style={{fontFamily: "Manrope"}}><b>Оперативная обстановка</b></span>
                </h5>
                <div className={'uk-alert uk-alert-primary'}>
                    <b>Это заглушка.</b>
                </div>
            </div>
            <div>
                <h5 className={"uk-heading-line"}>
                    <span style={{fontFamily: "Manrope"}}><b>Ниже отладочные инструменты</b></span>
                </h5>
                <button className="uk-button uk-button-primary" onClick={handleClick}>test</button>
                <button className="uk-button uk-button-primary uk-margin-left" onClick={handleKeepAlive}>keep-alive</button>
            </div>
        </div>
    </>);
}


/// eof ///
