function reviver(key, value) {
    // 2023-12-03T21:28:30.123Z
    const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value);
    }
    return value;
}


const getStorageValue = (name) => {
    try {
        return JSON.parse(window.localStorage.getItem(name), reviver);
    } catch (e) {
        return null;
    }
}


const setStorageValue = (name, value) => {
    try {
        if (value !== null) {
            window.localStorage.setItem(name, JSON.stringify(value));
        } else {
            window.localStorage.removeItem(name);
        }
    } catch (e) {
        // do nothing
    }
}


export {getStorageValue, setStorageValue};


/// eof ///
