import React from "react";
import {Phone, Sales} from "./Misc";


export default function Drivers() {
    return (<>
        <div>
            <div style={{width: "980px"}}>
                <h5 className={"uk-heading-line"}>
                    <span style={{fontFamily: "Manrope", fontSize: "16px"}}><b>Водители парка</b></span>
                </h5>
                <div className={'uk-alert uk-alert-primary'}>
                    <b>Не добавлено ни одного водителя.</b>
                </div>
                <div style={{fontSize: "16px"}}>
                    <hr/>
                    <div>
                        В данном разделе Вы можете воспользоваться Смарт-опцией «<b>Водители</b>».
                    </div>
                    <div style={{margin: "12px 0 0 0"}}>
                        Данная опция существенно расширяет функционал Личного Кабинета, позволяет получать информацию о
                        количестве перевезенных пассажиров, планируемой выручке, по каждому водителю или группам
                        водителей
                        за определенный, интересующий Вас интервал времени, что актуально, когда на одном ТС работает
                        несколько водителей.
                    </div>
                    <div style={{margin: "12px 0 0 0"}}>
                        При внедрении данной Смарт-опции Вы получаете удобный инструмент по оценке эффективности каждого
                        водителя на маршруте, также это повысит уровень персональной ответственности сотрудников.
                        Для запуска данной функции нам необходимо провести интеграцию с Вашей системой учета рабочего
                        времени водителей.
                    </div>
                    <div style={{margin: "12px 0 0 0"}}>
                        Смарт-опция «<b>Водители</b>» доступна на тарифе «<b>Стандарт</b>».
                    </div>
                    <hr/>
                    <div style={{margin: "12px 0 0 0"}}>
                        Задать интересующие Вас вопросы, а также оставить заявку на подключение опции Вы можете по
                        телефону <Phone/>, либо по электронной почте <Sales/>.
                    </div>
                </div>
            </div>
        </div>
    </>);
}


/// eof ///
