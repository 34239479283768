import axios from "axios";
import {formatLongDate, formatShortDate} from "./Misc";


const API_V1_BASE_URL =
    (process.env.NODE_ENV === 'development') ? 'http://localhost:8000/api/v1' : 'https://demo.smartneurotech.ru/api/v1';


const apiLogin = (username, password) => {
    console.log('apiLogin() called...');
    return axios.post(
        `${API_V1_BASE_URL}/login`,
        {username: username, password: password}
    );
}


const apiLogout = (accessToken, refreshToken) => {
    console.log('apiLogout() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };
    const body = {
        refresh: refreshToken
    };

    return axios.post(`${API_V1_BASE_URL}/logout`, body, config);
}


const apiRefresh = (accessToken, refreshToken) => {
    console.log('apiRefresh() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };
    const body = {
        refresh: refreshToken
    };

    return axios.post(`${API_V1_BASE_URL}/refresh`, body, config);
}


const apiProfile = (accessToken) => {
    console.log('apiProfile() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };

    return axios.get(`${API_V1_BASE_URL}/profile`, config);
}


const apiTransport = (accessToken) => {
    console.log('apiTransport() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };

    return axios.get(`${API_V1_BASE_URL}/transport`, config);
}


const apiRoutes = (accessToken) => {
    console.log('apiRoutes() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };

    return axios.get(`${API_V1_BASE_URL}/routes`, config);
}


const apiUpdateRoute = (accessToken, newRoute) => {
    console.log('apiUpdateRoute() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };
    const body = {
        id: newRoute.id,
        title: newRoute.title,
        cost: newRoute.cost,
        comment: newRoute.comment
    };

    return axios.post(`${API_V1_BASE_URL}/route`, body, config);
}


const apiDeleteRoute = (accessToken, routeId) => {
    console.log('apiDeleteRoute() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
        data: {
            id: routeId
        }
    };

    return axios.delete(`${API_V1_BASE_URL}/route`, config);
}


const apiUpdateVehicle = (accessToken, vehicle) => {
    console.log('apiUpdateRoute() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };
    const body = {
        id: vehicle.id,
        model: vehicle.model,
        route: vehicle.route,
        comment: vehicle.comment
    };

    return axios.post(`${API_V1_BASE_URL}/vehicle`, body, config);
}


const apiSummary = (accessToken) => {
    console.log('apiSummary() called...');

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`}
    };

    return axios.get(`${API_V1_BASE_URL}/summary`, config);
}


const apiReportOverall = (accessToken, fromMonth, toMonth, download) => {
    console.log(`apiReportOverall() called : from=${fromMonth}, to=${toMonth}`);

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
        params: {
            from: formatShortDate(fromMonth),
            to: formatShortDate(toMonth),
            download: download
        }
    };

    if (download) {
        config.responseType = 'blob';
    }
    return axios.get(`${API_V1_BASE_URL}/report/overall`, config);
}


const apiReportDaily = (accessToken, route, vehicle, fromDate, toDate, download) => {
    console.log(`apiReportDaily() called : route=${route}, vehicle=${vehicle}, from=${fromDate}, to=${toDate}`);

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
        params: {
            from: formatLongDate(fromDate),
            to: formatLongDate(toDate),
            mode: (route !== null) ? 'route' : 'vehicle',
            id: (route !== null) ? route : vehicle,
            download: download
        }
    };

    if (download) {
        config.responseType = 'blob';
    }
    return axios.get(`${API_V1_BASE_URL}/report/daily`, config);
}


const apiReportIntraday = (accessToken, route, vehicle, date, download) => {
    console.log(`apiReportIntraday() called : route=${route}, vehicle=${vehicle}, date=${date}`);

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
        params: {
            date: formatLongDate(date),
            mode: (route !== null) ? 'route' : 'vehicle',
            id: (route !== null) ? route : vehicle,
            download: download
        }
    };

    if (download) {
        config.responseType = 'blob';
    }
    return axios.get(`${API_V1_BASE_URL}/report/intraday`, config);
}


const apiReportStoppage = (accessToken, vehicle, date, hour) => {
    console.log(`apiReportStoppage() called : vehicle=${vehicle}, date=${date}, hour=${hour}`);

    const config = {
        headers: {Authorization: `Bearer ${accessToken}`},
        params: {
            vehicle: vehicle,
            date: formatLongDate(date),
            hour: hour
        }
    };

    return axios.get(`${API_V1_BASE_URL}/report/stoppage`, config);
}


export {
    apiLogin,
    apiLogout,
    apiRefresh,
    apiProfile,
    apiTransport,
    apiRoutes,
    apiUpdateRoute,
    apiDeleteRoute,
    apiUpdateVehicle,
    apiSummary,
    apiReportOverall,
    apiReportDaily,
    apiReportIntraday,
    apiReportStoppage
};


/// eof ///
