import React from "react";
import {Phone, Sales} from "./Misc";


export default function Integrations() {
    return (<>
        <div>
            <div style={{width: "980px", fontSize: "16px"}}>
                <h5 className={"uk-heading-line"}>
                    <span style={{fontFamily: "Manrope"}}><b>Интеграции</b></span>
                </h5>
                <div>
                    В данном разделе Вы можете воспользоваться Смарт-опцией «<b>Интеграции</b>».
                </div>
                <div style={{margin: "12px 0 0 0"}}>
                    <b>Сервис ОСА-ПТ</b> является современной аналитической платформой, обладающей возможностью отправки данных
                    в сторонние информационные сервисы и различные системы мониторинга транспорта.
                </div>
                <div style={{margin: "12px 0 0 0"}}>
                    Основные сферы применения данной опции:
                    <ul>
                        <li style={{margin: "12px 0 0 0"}}>
                            Выгрузка данных в любую стороннюю информационную систему для объединения данных, вывода
                            отчетов в формате сторонней системы, что особенно актуально, если Вы уже пользуетесь такими
                            системами, и Вам нужна автоматизации в обработке данных.
                        </li>
                        <li style={{margin: "12px 0 0 0"}}>
                            Передача данных в региональные навигационно-информационные системы, для исполнения
                            регламентов пассажироперевозок, получения субсидий и т.п.
                        </li>
                        <li style={{margin: "12px 0 0 0"}}>
                            Интеграция с системами учета рабочего времени, такими как 1С, что позволяет делать запросы
                            в <b>Сервис ОСА-ПТ</b> и забирать данные по Вашим сотрудникам о количестве перевезенных
                            пассажиров и выручке в соответствии с рабочими сменами.
                        </li>
                        <li style={{margin: "12px 0 0 0"}}>
                            Взаимодействие с бухгалтерской системой отчетности для выявления отклонений расчетной
                            выручки от фактической.
                        </li>
                        <li style={{margin: "12px 0 0 0"}}>
                            Интеграция с бортовыми системами оплаты и валидаторами для контроля безбилетного проезда и
                            недостачи наличных средств.
                        </li>
                        <li style={{margin: "12px 0 0 0"}}>
                            Автоматизация отправки данных, что актуально в том случае, если Вам необходимо выгружать
                            данные на регулярной основе с определенной периодичностью в стороннюю информационную систему.
                        </li>
                    </ul>
                </div>
                <hr/>
                <div style={{margin: "12px 0 0 0"}}>
                    Задать интересующие Вас вопросы, а также оставить заявку на подключение опции Вы можете по
                    телефону <Phone/> либо по электронной почте <Sales/>.
                </div>
            </div>
        </div>
    </>);
}


/// eof ///
