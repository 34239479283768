import React, {useState} from "react";
import {getStorageValue} from "./storage";


const calendarStyling = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid #404040;
  }
  .my-selected:hover:not([disabled]) { 
    border-color: grey;
    color: black;
  }
  .my-today { 
    font-weight: 600;
    color: black;
    background-color: #f0f0f0;
  }
  .my-outside { 
    color: #ededed;
  }
`;


const basePointClass = `
  .reportBasePoint {
  }
`;


const TabMenu = ({items, mode, setMode}) => {
    const selectedTabStyle = {borderBottom: "3px solid #1e87f0", color: "#1e87f0"};

    const switcher = (e) => {
        e.preventDefault();

        const newMode = parseInt(e.target.id);

        if ((newMode !== undefined) && !isNaN(newMode)) {
            setMode(newMode);
        }
    };

    return (<>
        <ul data-uk-tab onClick={switcher} className={"uk-margin-remove-top uk-margin-remove-bottom"}>
            {items.map((item, key) => {
                const name = (mode === key) ? "uk-active" : null;
                const styling = (mode === key) ? selectedTabStyle : null;

                return (
                    <li key={key} className={name}>
                        <a id={key} style={styling}>{item}</a>
                    </li>
                );
            })}
        </ul>
    </>);
}


export default function Analytics() {
    const MODE_KEY_NAME = "analyticsMode";

    const [mode, setMode] = useState(getStorageValue(MODE_KEY_NAME) || 0);
    const reportTypes = ['Загрузка маршрута'];

    return (<>
        <style>{calendarStyling}</style>
        <style>{basePointClass}</style>
        <div style={{display: "block"}}>
            <div>
                <div style={{display: "block"}}>
                    <div style={{width: "980px"}}>
                        <div className={"uk-margin-bottom"}>
                            <TabMenu items={reportTypes} mode={mode} setMode={setMode}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);}


/// eof ///
