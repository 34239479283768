import React, {useEffect, useRef, useState} from "react";
import UIkit from "uikit";
import {apiProfile, apiSummary} from './api';
import {getAccessToken} from "./Auth";
import {Link, useNavigate} from "react-router-dom";
import {getStorageValue, setStorageValue} from "./storage";
import {Phone, Spinner, Support, Rubles} from "./Misc";
import {isFirstDayOfMonth} from "date-fns";


const PROFILE_KEY_NAME = "profile";
const SUMMARY_KEY_NAME = "summary";


const SmallSpinner = () => {
    return (<div data-uk-spinner="ratio: 0.5" style={{color: "#d0d0d0"}}></div>);
}


export default function Profile() {
    const navigate = useNavigate();
    const uiRef = useRef(null);
    const isLoadingProfile = useRef(false);
    const isLoadingSummary = useRef(false);
    const [loading, setLoading] = useState(true);
    const [isSummary, setIsSummary] = useState(false);

    function handleClick(e) {
        e.preventDefault();

        const element = uiRef.current;

        UIkit.modal(element).show();
    }

    const data = getStorageValue(PROFILE_KEY_NAME);
    const summary = getStorageValue(SUMMARY_KEY_NAME);

    useEffect(() => {
        const data = getStorageValue(PROFILE_KEY_NAME);

        console.log(`Profile.useEffect(): data = ${JSON.stringify(data)}`);
        if (data) {
            setLoading(false);
        } else if (!isLoadingProfile.current) {
            isLoadingProfile.current = true;

            const accessToken = getAccessToken();

            console.log(`Profile.useEffect(): token = ${accessToken}`);
            apiProfile(accessToken).then((result) => {
                const response = result.data;

                console.log(`Profile.useEffect(): profile result = ${JSON.stringify(response)}`);
                setStorageValue(PROFILE_KEY_NAME, response);
                setLoading(false);
            }).catch((e) => {
                console.log(`Profile.useEffect(): [1] e = ${e}`);
                navigate('/error');
            });
        }
        if (summary) {
            setIsSummary(true);
        } else if (!isLoadingSummary.current) {
            isLoadingSummary.current = true;

            const accessToken = getAccessToken();

            apiSummary(accessToken).then((result) => {
                const response = result.data;

                console.log(`Profile.useEffect(): summary result = ${JSON.stringify(response)}`);
                setStorageValue(SUMMARY_KEY_NAME, response.summary);  // store array only
                setIsSummary(true);
            }).catch((e) => {
                console.log(`Profile.useEffect(): [2] e = ${e}`);
                navigate('/error');
            });
        }
    }, []);

    const predict = (v) => {
        if (!v) {
            return 0;
        }

        const now = new Date();
        const base = now.getDate() - 1;

        if (!base) {
            return 0;
        }

        const days = new Date(
            now.getFullYear(),
            (now.getMonth() < 12) ? (now.getMonth() + 1) : 0,
            0
        ).getDate();

        return v / base * days;
    }
    const NumberField = ({value}) => {
        return (Number(value) === value && value > 0) ? (<span>{value.toFixed(0)}</span>) : (<span>-</span>);
    }
    const monthToText = (value) => {
        const date = new Date();
        const current = date.getMonth();
        let year = date.getFullYear();

        value -= 1;
        if (value > current) {
            year -= 1;
        }
        date.setMonth(value);
        return `${date.toLocaleString('ru-RU', { month: 'long' })} ${year}`;
    }
    const normalRow = "#e5f7b0";
    const highlightedRow = "#f8ffc8";
    const doPredict = !isFirstDayOfMonth(new Date());

    return (<>
        <div style={{display: "block"}}>
            {!loading &&
                <div>
                    <div style={{width: "980px"}}>
                        <h5 className={"uk-heading-line"}>
                            <span style={{fontFamily: "Manrope"}}><b>Параметры парка</b></span>
                        </h5>
                        <div style={{fontSize: "16px"}}>
                            <div style={{display: "inline-block", width: "490px", padding: "0 0 4px 0"}}>
                                <div style={{display: "inline-block", width: "350px"}}>
                                    Общее количество транспортных средств
                                </div>
                                <div style={{display: "inline-block", width: "70px"}}>
                                    <b>{data?.customer?.vehicles || "-"}</b>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "490px"}}>
                                <div style={{display: "inline-block", width: "320px", padding: "0 0 0 32px"}}>
                                    Количество маршрутов
                                </div>
                                <div style={{display: "inline-block"}}>
                                    <b>{data?.customer?.routes || <Link to={"/routes"}>Добавить?</Link>}</b>
                                </div>
                            </div>
                            <br/>
                            <div style={{display: "inline-block", width: "490px", padding: "4px 0 0 0"}}>
                                <div style={{display: "inline-block", width: "350px"}}>
                                    Общее количество каналов
                                </div>
                                <div style={{display: "inline-block", width: "70px"}}>
                                    <b>{data?.customer?.channels || "-"}</b>
                                </div>
                            </div>
                            <div style={{display: "inline-block", width: "490px"}}>
                                <div style={{display: "inline-block", width: "320px", padding: "0 0 0 32px"}}>
                                    Количество водителей
                                </div>
                                <div style={{display: "inline-block"}}>
                                    <b>{data?.customer?.drivers || <Link to={"/routes"}>Добавить?</Link>}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "32px"}}>
                        <h5 className={"uk-heading-line"}>
                            <span style={{fontFamily: "Manrope"}}><b>Статистика перевозок</b></span>
                        </h5>
                        <div>
                            <table className="uk-table uk-table-striped uk-table-hover uk-table-small"
                                   style={{width: "980px"}}>
                                <thead>
                                <tr>
                                    <th><b>Показатель</b></th>
                                    <th style={{width: "180px", backgroundColor: highlightedRow}}>
                                        {isSummary ?
                                            (<div>
                                                <b>{monthToText(summary[3]?.month)}</b><br/>
                                                (факт/прогноз)
                                            </div>) : (<SmallSpinner/>)}
                                    </th>
                                    <th style={{width: "135px"}}>
                                        {isSummary ? (<b>{monthToText(summary[2]?.month)}</b>) : (<SmallSpinner/>)}
                                    </th>
                                    <th style={{width: "135px"}}>
                                        {isSummary ? (<b>{monthToText(summary[1]?.month)}</b>) : (<SmallSpinner/>)}
                                    </th>
                                    <th style={{width: "135px"}}>
                                        {isSummary ? (<b>{monthToText(summary[0]?.month)}</b>) : (<SmallSpinner/>)}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Перевезено пассажиров</td>
                                    <td style={{backgroundColor: normalRow}}>
                                        {isSummary ? (<div>
                                            <NumberField value={summary[3]?.pass} /> / {doPredict ? <NumberField value={predict(summary[3]?.pass)} /> : "-"}
                                        </div>) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<NumberField value={summary[2]?.pass} />) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<NumberField value={summary[1]?.pass} />) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<NumberField value={summary[0]?.pass} />) : (<SmallSpinner/>)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Расчётная выручка
                                        <div className="uk-inline" style={{verticalAlign: "baseline"}}>
                                                    <span className="uk-margin-small-left"
                                                          data-uk-icon="icon: question; ratio: 0.7"></span>
                                            <div className="uk-card-small uk-card-body uk-card-default"
                                                 uk-drop="mode: hover; duration: 0; delay-hide: 0" style={{color: "black"}}>
                                                Расчётная выручка формируется <b>только по тем ТС которые назначены на
                                                маршруты</b> и для которых указана стоимость проезда по маршруту.
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{backgroundColor: highlightedRow}}>
                                        {isSummary ? (<div>
                                                <Rubles value={summary[3]?.cost}/> / {doPredict ? <Rubles value={predict(summary[3]?.cost)}/> : "-"}
                                        </div>) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<Rubles value={summary[2]?.cost}/>) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<Rubles value={summary[1]?.cost}/>) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<Rubles value={summary[0]?.cost}/>) : (<SmallSpinner/>)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Средняя выручка на одно транспортное средство
                                        <div className="uk-inline" style={{verticalAlign: "baseline"}}>
                                                    <span className="uk-margin-small-left"
                                                          data-uk-icon="icon: question; ratio: 0.7"></span>
                                            <div className="uk-card-small uk-card-body uk-card-default"
                                                 uk-drop="mode: hover; duration: 0; delay-hide: 0" style={{color: "black"}}>
                                                Средняя выручка на одно транспортное средство вычисляется <b>только по
                                                тем ТС которые назначены на маршруты</b> и для которых указана
                                                стоимость проезда по маршруту.
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{backgroundColor: normalRow}}>
                                        {isSummary ? (<div>
                                            <Rubles value={summary[3]?.avg}/> / {doPredict ? <Rubles value={predict(summary[3]?.avg)}/> : "-"}
                                        </div>) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<Rubles value={summary[2]?.avg}/>) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<Rubles value={summary[1]?.avg}/>) : (<SmallSpinner/>)}
                                    </td>
                                    <td>
                                        {isSummary ? (<Rubles value={summary[0]?.avg}/>) : (<SmallSpinner/>)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className={'uk-alert uk-alert-primary'}>
                                Прогноз по перевезенным пассажирам и выручке рассчитывается автоматически начиная со второго числа каждого месяца.
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "44px"}}>
                        <div style={{display: "inline-block", verticalAlign: "top", width: "480px", margin: "0 20px 0 0"}}>
                            <h5 className={"uk-heading-line"}>
                                <span style={{fontFamily: "Manrope"}}><b>Информация об организации</b></span>
                            </h5>
                            <div style={{width: "480px"}}>
                                <div className="uk-column-1-2">
                                <div style={{lineHeight: "2"}}>
                                    ИНН<br/>
                                    Основное контактное лицо<br/>
                                    Должность<br/>
                                    Телефон<br/>
                                    Электронная почта<br/>
                                    Часовой пояс<br/>
                                </div>
                                <div style={{lineHeight: "2"}}>
                                    {data?.customer?.taxnum || "-"}<br/>
                                    {data?.manager?.name || "-"}<br/>
                                    {data?.manager?.jt || "-"}<br/>
                                    {data?.manager?.phone || "-"}<br/>
                                    {data?.manager?.email || "-"}<br/>
                                    {data?.customer?.tz || "-"}<br/>
                                </div>
                            </div>
                            </div>
                            <div style={{marginTop: "16px"}}>
                                <div ref={uiRef} className="uk-flex-top" data-uk-modal>
                                    <div className={"uk-modal-dialog uk-modal-body uk-margin-auto-vertical"}>
                                        <p>
                                            Смена основного контактного лица и информации об организации в настоящее
                                            время доступна только через техническую поддержку.
                                        </p>
                                        <p>
                                            Обратитесь, пожалуйста, по телефону <Phone/> или по электронной почте <Support/>.
                                        </p>
                                        <p className="uk-text-right">
                                            <button className="uk-button uk-button-primary uk-modal-close" type="button">
                                                Закрыть
                                            </button>
                                        </p>
                                    </div>
                                </div>
                                <button className="uk-button uk-button-primary" onClick={handleClick}>Сменить</button>
                            </div>
                        </div>
                        <div style={{display: "inline-block", verticalAlign: "top", width: "480px"}}>
                            <h5 className={"uk-heading-line"}>
                                <span style={{fontFamily: "Manrope"}}><b>Текущий пользователь</b></span>
                            </h5>
                            <div style={{width: "480px"}}>
                                <div className="uk-column-1-2">
                                    <div style={{lineHeight: "2"}}>
                                        Логин<br/>
                                        ФИО<br/>
                                        Должность<br/>
                                        Телефон<br/>
                                        Электронная почта<br/>
                                    </div>
                                    <div style={{lineHeight: "2"}}>
                                        {data?.user?.login || "-"}<br/>
                                        {data?.user?.name || "-"}<br/>
                                        {data?.user?.jt || "-"}<br/>
                                        {data?.user?.phone || "-"}<br/>
                                        {data?.user?.email || "-"}<br/>
                                    </div>
                                </div>
                            </div>
                            <div className={'uk-alert uk-alert-primary'}>
                                Сменить данные текущего пользователя можно в разделе настроек.
                            </div>
                        </div>
                    </div>
                </div>
            }
            {loading && <Spinner/>}
        </div>
    </>);
}


export {PROFILE_KEY_NAME, SUMMARY_KEY_NAME};


/// eof ///
