import React from "react";
import './App.css';
import {Routes, Route, Navigate, useNavigate} from "react-router-dom";
import {styled} from "styled-components";
import {Phone, Support, FixedBox, CenterBox, FullViewport} from "./Misc";
import {SinglelineCopyright} from './Copyright';
import {NavPanel, navPanelWidth} from "./Navpanel";
import {AuthProvider, useAuth, ProtectedRoute} from "./Auth";
import Title from "./Title";
import Login from "./Login";
import {Logoff, Timeout, Lifetime} from "./Logoff";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import Transport from "./Transport";
import Finance from "./Finance";
import Drivers from "./Drivers";
import AppRoutes from "./AppRoutes";
import Integrations from "./Integrations";
import Reports from "./Reports";
import Settings from "./Settings";
import Analytics from "./Analytics";
import {getCustomerName} from "./Auth";


const AppPageStyle = styled.div`
  margin: 10px 10px 10px ${navPanelWidth + 10}px;
  min-width: 800px;
  max-width: 1320px;
  font-size: 14px;
  color: black;
`;

const TitleStyle = styled.div`
  font-size: 24px;
  color: black;
  margin-bottom: 16px;
`;

const FlexSection = styled.div`
  display: flex;
  align-items: flex-start;
`;


function Home() {
    return (<>
        <Navigate to={"/login"}/>
    </>);
}


function Recovery() {
    const navigate = useNavigate();
    const handleClick = (e) => {
        e.preventDefault();
        navigate('/login');
    };

    return (<>
        <FixedBox>
            <Title vertical={false}/>
        </FixedBox>
        <FullViewport>
            <CenterBox $width={400} style={{color: "black"}}>
                <div className="uk-margin" style={{color: "#d70000"}}>
                    Неправильное имя пользователя или пароль.
                </div>
                <button className="uk-button uk-button-primary" onClick={handleClick}>Вернуться</button>
                <div className={'uk-alert'} style={{fontSize: "12px"}}>
                    В случае <b style={{color: "black"}}>утраты пароля и/или имени пользователя</b> для
                    восстановления доступа, пожалуйста, обратитесь к нам по многоканальному
                    телефону <Phone/> или по электронной почте <Support/>.
                </div>
            </CenterBox>
            <SinglelineCopyright/>
        </FullViewport>
    </>);
}


function Unresponsive() {
    const navigate = useNavigate();
    const handleClick = (e) => {
        e.preventDefault();
        navigate('/login');
    };

    return (<>
        <FixedBox>
            <Title vertical={false}/>
        </FixedBox>
        <FullViewport>
            <CenterBox $width={400} style={{color: "black"}}>
                <div className="uk-margin">
                    За заданное время не был получен ответ от сервера. Возможно, пропало сетевое соединение,
                    какие-то проблемы на сервере или локальном компьютере.
                </div>
                <div className="uk-margin">
                    Пожалуйста, попробуйте зайти позднее.
                </div>
                <button className="uk-button uk-button-primary" onClick={handleClick}>Вернуться</button>
                <div className={'uk-alert'} style={{fontSize: "12px"}}>
                    Если вы видите эту страницу не первый раз, пожалуйста, обратитесь к нам в службу
                    технической поддержки по телефону <Phone/> или по электронной почте <Support/>.
                </div>
            </CenterBox>
            <SinglelineCopyright/>
        </FullViewport>
    </>);
}


function Error() {
    const navigate = useNavigate();
    const {handleLogout} = useAuth();
    const handleBack = (e) => {
        e.preventDefault();
        navigate(-1);
    };
    const handleLogin = (e) => {
        e.preventDefault();
        handleLogout();
        navigate('/login');
    };

    return (<>
        <FixedBox>
            <Title vertical={false}/>
        </FixedBox>
        <FullViewport>
            <CenterBox $width={400} style={{color: "black"}}>
                <div className="uk-margin">
                    Что-то пошло не так...
                </div>
                <div className="uk-margin">
                    Попробуйте перезагрузить страницу через несколько минут.
                </div>
                <button className="uk-button uk-button-default uk-margin-right" onClick={handleBack}>Вернуться</button>
                <button className="uk-button uk-button-primary" onClick={handleLogin}>Войти снова</button>
                <div className={'uk-alert'} style={{fontSize: "12px"}}>
                    Если вы видите эту страницу не первый раз, пожалуйста, обратитесь к нам в службу
                    технической поддержки по телефону <Phone/> или по электронной почте <Support/>.
                </div>
            </CenterBox>
            <SinglelineCopyright/>
        </FullViewport>
    </>);
}


function composeCustomerTitle() {
    const title = getCustomerName();

    return title?.replace(/(?![^<]*>)"([^"]+)"/, "\u00ab$1\u00bb");
}


function MainSection({props}) {
    return (<>
        <ProtectedRoute>
            <NavPanel/>
            <AppPageStyle>
                <TitleStyle>
                    <b>{composeCustomerTitle()}</b>
                </TitleStyle>
                <FlexSection>
                    {
                        {
                            'profile': <Profile props={props}/>,
                            'dashboard': <Dashboard props={props}/>,
                            'reports': <Reports props={props}/>,
                            'analytics': <Analytics props={props}/>,
                            'transport': <Transport props={props}/>,
                            'routes': <AppRoutes props={props}/>,
                            'drivers': <Drivers props={props}/>,
                            'integrations': <Integrations props={props}/>,
                            'finance': <Finance props={props}/>,
                            'settings': <Settings props={props}/>,
                        }[props.section] || <Profile props={props}/>
                    }
                </FlexSection>
            </AppPageStyle>
        </ProtectedRoute>
    </>);
}


export default function App() {
    return (<>
        <AuthProvider>
            <Routes>
                <Route path="*" element={<Error/>}/>
                <Route exact path="/" element={<Home/>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/logoff" element={<Logoff/>}/>
                <Route exact path="/recovery" element={<Recovery/>}/>
                <Route exact path="/unresponsive" element={<Unresponsive/>}/>
                <Route exact path="/timeout" element={<Timeout/>}/>
                <Route exact path="/lifetime" element={<Lifetime/>}/>

                <Route exact path="/profile" element={<MainSection props={{section: "profile"}}/>}/>
                {/*<Route exact path="/dashboard" element={<MainSection props={{section: "dashboard"}}/>}/>*/}
                <Route exact path="/reports" element={<MainSection props={{section: "reports"}}/>}/>
                {/*<Route exact path="/analytics" element={<MainSection props={{section: "analytics"}}/>}/>*/}
                <Route exact path="/transport" element={<MainSection props={{section: "transport"}}/>}/>
                <Route exact path="/routes" element={<MainSection props={{section: "routes"}}/>}/>
                <Route exact path="/drivers" element={<MainSection props={{section: "drivers"}}/>}/>
                <Route exact path="/integrations" element={<MainSection props={{section: "integrations"}}/>}/>
                {/*<Route exact path="/finance" element={<MainSection props={{section: "finance"}}/>}/>*/}
                <Route exact path="/settings" element={<MainSection props={{section: "settings"}}/>}/>
            </Routes>
        </AuthProvider>
    </>);
}


// eof
