import {styled, css} from "styled-components";


const TitleStyling = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin: 6px 6px 12px 6px;
  height: 50px;
  ${(props) => {
      if (!props.$vertical) {
          return css`
            display: flex
          `;
      }
  }}
`;

const ServiceBox = styled.div`
  font-weight: 500;
  ${(props) => {
    if (!props.$vertical) {
      return css`
        margin: 2.5px 0 0 8px;
      `;
    }
  }}
`;

const ServiceTitle = styled.div`
  font-size: 9px;
  height: 9px;
`;

const ServiceType = styled.div`
  font-size: 9.48px;
  height: 9px;
`;


export default function Title(props) {
    return (<>
        <TitleStyling $vertical={props.vertical}>
            <div>ОСА-ПТ</div>
            <ServiceBox $vertical={props.vertical}>
                <ServiceTitle>облачный сервис аналитики</ServiceTitle>
                <ServiceType>пассажирского трафика</ServiceType>
            </ServiceBox>
        </TitleStyling>
    </>);
}


// eof
