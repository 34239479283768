import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Title from "./Title";
import {SinglelineCopyright} from "./Copyright";
import {Sales, Phone, Support, FixedBox, FullViewport, CenterBox} from './Misc';
import {useAuth} from "./Auth";


export default function Login() {
    const navigate = useNavigate();
    const [canLogin, setCanLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const {handleLogin} = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const username = e.target.username.value;
        const password = e.target.password.value;

        setCanLogin(false);
        handleLogin(username, password, () => {
            navigate('/profile');
        }, (status) => {
            if (status === 401 || status === 403) {  // 401 - Unauthorized, 403 - Forbidden
                navigate('/recovery');
            } else if (status === 500) {  // 500 - Internal Server Error
                navigate('/error');
            } else {
                navigate('/unresponsive');
            }
        });
    };
    const handleChange = (e) => {
        setCanLogin(e.target.value.length > 0);
    };

    return (<>
        <FixedBox>
            <Title vertical={false}/>
        </FixedBox>
        <FullViewport>
            <CenterBox $width={400} style={{color: "black"}}>
                <div className="uk-inline">
                    <div className="uk-margin"><b>Авторизация</b></div>
                    <form className="uk-form-stacked" onSubmit={handleSubmit}>
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Имя пользователя:</label>
                            <div className="uk-form-controls">
                                <input autoFocus className="uk-input" style={{color: "black"}} name="username"
                                       type="text"
                                       onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="uk-margin">
                            <label className="uk-form-label" htmlFor="form-stacked-text">Пароль:</label>
                            <div className="uk-form-controls">
                                <input className="uk-input" style={{color: "black"}} name="password" type="password"/>
                            </div>
                        </div>
                        <button className="uk-button uk-button-primary" type="submit" disabled={!canLogin}>Войти
                        </button>
                    </form>
                    <div className={'uk-alert'} style={{fontSize: "12px"}}>
                        В случае <b style={{color: "black"}}>утраты пароля и/или имени пользователя</b> для
                        восстановления доступа, пожалуйста, обратитесь к нам по
                        телефону <Phone/> или по электронной почте <Support/>.
                        <hr/>
                        Для <b style={{color: "black"}}>регистрации и получения доступа</b> позвоните
                        вашему персональному менеджеру или напишите нам по электронной почте <Sales/>.
                        <hr/>
                        Для получения <b style={{color: "#0064ff"}}>демонстрационного доступа</b> обратитесь в
                        отдел продаж по телефону <Phone/>.
                    </div>
                    {loading === true &&
                        <div className="uk-overlay-default uk-position-cover">
                            <div style={{
                                width: "100%",
                                height: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                color: "blue"
                            }}>
                                <div data-uk-spinner="ratio: 1.6"></div>
                            </div>
                        </div>
                    }
                </div>
            </CenterBox>
            <SinglelineCopyright/>
        </FullViewport>
    </>);
}


/// eof ///
