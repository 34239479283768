import {styled, css} from "styled-components";
import {Link} from "react-router-dom";
import Title from "./Title";
import {MultilineCopyright} from './Copyright';


const menuWidth = 168;
const borderWidth = 2;
const secondColor = '#f0f0f0';


const MenuBox = styled.div`
  width: ${menuWidth}px;
  height: 100vh;
  position: fixed;
`;

const MenuStyle = styled.div`
  margin: 8px 0 8px 0;
  font-size: 16px;
  font-weight: 500;
`;

const MenuItemStyle = styled.div`
  padding: 6px 8px 6px 8px;
  ${(props) => {
    if (props.$selected) {
      return css`
        background: ${secondColor};
      `;
    }
  }}
`;

const MenuRightBorder = styled.div`
  width: ${borderWidth}px;
  height: 100vh;
  position: fixed;
  margin-left: ${menuWidth}px;
`;

const BorderFiller = styled.div`
  width: 100%;
  height: 100%;
  background: ${secondColor};
`;


function NavPanel() {
    const linkStyle = {
        textDecoration: "none",
    };
    const items = [
        {path: '/profile', title: 'Профиль'},
        // {path: '/dashboard', title: 'Дашборд'},
        {path: '/reports', title: 'Отчёты'},
        // {path: '/analytics', title: 'Аналитика'},
        {path: '/transport', title: 'Транспорт'},
        {path: '/routes', title: 'Маршруты'},
        {path: '/drivers', title: 'Водители'},
        {path: '/integrations', title: 'Интеграции'},
        // {path: '/finance', title: 'Финансы'},
        {path: '/settings', title: 'Настройки'},
        {path: '/logoff', title: 'Выход'}
    ];
    let index = 0;

    return (<>
        <MenuBox>
            <Title vertical={true}/>
            <MenuStyle>
                {items.map(item => {
                    index += 1;
                    return (
                        <MenuItemStyle $selected={(window.location.pathname === item.path)} key={index}>
                            <Link to={item.path} style={linkStyle}>{item.title}</Link>
                        </MenuItemStyle>
                    );
                })}
            </MenuStyle>
            <MultilineCopyright/>
        </MenuBox>
        <MenuRightBorder>
            <BorderFiller/>
        </MenuRightBorder>
    </>);
}


const navPanelWidth = menuWidth + borderWidth;


export {NavPanel, navPanelWidth};


// eof
