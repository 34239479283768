import React from "react";
import {css, styled} from "styled-components";


const Sales = () => {
    return (<a href={'mailto:sales@smartneurotech.ru'}><b>sales@smartneurotech.ru</b></a>);
};


const Phone = () => {
    return (<a href={"tel:+78001012110"}><b style={{color: "black"}}>8-800-10-121-10</b></a>);
};


const Support = () => {
    return (<a href={'mailto:support@smartneurotech.ru'}><b>support@smartneurotech.ru</b></a>);
};


const Spinner = () => {
    return (<>
        <div className="uk-overlay-default uk-position-cover">
            <div style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                color: "blue"
            }}>
                <div data-uk-spinner="ratio: 1.6"></div>
            </div>
        </div>
    </>);
};


const Version = () => {
    return (<>
        <div style={{
            width: "100%",
            textAlign: "center",
            fontSize: "11px",
            fontWeight: "600",
            color: "black",
            backgroundColor: "#f0f0f0"
        }}>
            <div style={{padding: "8px", margin: "0"}}>
                Версия ПО: R4<br/>
            </div>
        </div>
    </>);
}


const Rubles = ({value}) => {
    return (Number(value) === value && value > 0) ? (<span>{value.toFixed(0)}&nbsp;&#8381;</span>) : (<span>-</span>);
}


const FixedBox = styled.div`
  position: fixed;
`;


const FullViewport = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;


const CenterBox = styled.div`
  ${(props) => {
    if (props.$width) {
      return css`
        width: ${props.$width}px;
      `;
    }
  }};
  ${(props) => {
    if (props.$height) {
      return css`
        height: ${props.$height}px;
      `;
    }
  }};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;


const PagerArrow = styled.span`
  &:hover {
    color: black;
  }
  cursor: pointer;
`;


const PagerItem = styled.span`
  ${(props) => {
    if (props.$selected) {
        return css`
        color: black;
        background-color: #f0f0f0;
        font-weight: bold;
      `;
    }
    return css`
      &:hover {
        color: black;
        cursor: pointer;
      }
    `;
}};
`;


const TableHead = styled.b`
    font-size: 12px;
`;


const Pager = ({curPage, totalPages, setCurPage}) => {
    const LeftArrow = ({enabled}) => {
        return (
            <li className={enabled ? "uk-disabled" : null} key={-1} data-page={-1}>
                <PagerArrow data-uk-pagination-previous></PagerArrow>
            </li>
        );
    };
    const RightArrow = ({enabled}) => {
        return (
            <li className={enabled ? "uk-disabled" : null} key={-2} data-page={-2}>
                <PagerArrow data-uk-pagination-next></PagerArrow>
            </li>
        );
    };
    const Item = ({index, selected}) => {
        return (
            <li className={selected ? "uk-active" : null} data-page={index}>
                <PagerItem $selected={selected}>{index + 1}</PagerItem>
            </li>
        );
    };
    const Pages = ({selected, count}) => {
        let content = [];

        for (let i = 0; i < count; ++i) {
            content.push(<Item index={i} selected={(i === selected)} key={i}/>);
        }
        return content;
    };
    const onSelect = (e) => {
        e.preventDefault();

        let node = e.target;
        let selected = null;

        while (node !== e.currentTarget) {
            selected = node.getAttribute('data-page');
            if (selected) {
                break;
            }
            node = node.parentNode;
        }
        if (selected) {
            let newPage = null;

            switch (selected) {
                case null:
                    // do nothing
                    break;

                case '-1':
                    newPage = Math.max(curPage - 1, 0);
                    break;

                case '-2':
                    newPage = Math.min(curPage + 1, totalPages - 1);
                    break;

                default:
                    newPage = Number(selected);
            }
            // console.log(`pager: selected=${selected}, curPage=${curPage}, newPage=${newPage}, total=${totalPages}`);
            if (newPage !== curPage) {
                setCurPage(newPage);
            }
        }
    };

    return (
        <div>
            <ul className={"uk-pagination uk-flex-right uk-margin-remove-top uk-margin-remove-bottom"}
                style={{alignItems: "center"}} onClick={(e) => onSelect(e)}
            >
                <LeftArrow enabled={(curPage <= 0)}/>
                <Pages selected={curPage} count={totalPages}/>
                <RightArrow enabled={(curPage >= (totalPages - 1))}/>
            </ul>
        </div>
    );
}


const calcChunkCount = (arrayLike, itemPerChunk) => {
    return arrayLike ? Math.floor(arrayLike.length / itemPerChunk) + ((arrayLike.length % itemPerChunk) ? 1 : 0) : 1;
}


const formatShortDate = (d) => {
    const year = d.getFullYear();
    let m = d.getMonth() + 1;

    return `${year}` + ((m < 10) ? `0${m}` : m);
}


const formatLongDate = (d) => {
    const m = d.getMonth() + 1;
    const day = d.getDate();

    return `${d.getFullYear()}` + ((m < 10) ? `0${m}` : m) + ((day < 10) ? `0${day}` : day);
}


export {
    Sales, Phone, Support, Spinner, Version, Rubles, Pager, calcChunkCount, formatShortDate, formatLongDate,
    FixedBox, FullViewport, CenterBox, TableHead
};


/// eof ///
