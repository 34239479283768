import {createContext, useContext, useMemo, useRef} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import {useIdleTimer} from "react-idle-timer";
import {apiLogin, apiLogout, apiRefresh} from './api';
import {getStorageValue, setStorageValue} from "./storage";


const ACCESS_TOKEN_NAME = "accessToken";
const REFRESH_TOKEN_NAME = "refreshToken";

const CUSTOMER_KEY_NAME = "customer";

const INACTIVITY_TIMEOUT_SEC = 15 * 60;  // 15 minutes
const ACCESS_REFRESH_GAP_SEC = 5;  // 5 seconds


const getAccessToken = () => {
    return getStorageValue(ACCESS_TOKEN_NAME);
}


const getCustomerName = () => {
    return getStorageValue(CUSTOMER_KEY_NAME);
}


const AuthContext = createContext(null);


const useAuth = () => {
    return useContext(AuthContext);
};


const resetAuth = () => {
    window.localStorage.clear();
}


const isTokenValid = (token) => {
    const props = ['type', 'exp', 'iat', 'jti', 'uid', 'username', 'version', 'iss'];
    let result = true;

    props.forEach((v) => {
        if (!token.hasOwnProperty(v)) {
            result = false;
        }
    });
    return result;
}


const utcTimestampToLocal = (utcTimestamp) => {
    const ts = new Date().getTimezoneOffset();

    return new Date((utcTimestamp - ts * 60) * 1000);
}


const localDateToISO = (date) => {
    const s = date.toISOString();

    return s.slice(0, s.length - 5).replace('T', ' ');
}


const dumpToken = (token) => {
    const exp = utcTimestampToLocal(token.exp);
    const iat = utcTimestampToLocal(token.iat);

    console.log(
        `token dump:\n` +
        `   type = ${token.type}\n` +
        `   expire = ${localDateToISO(exp)}, issued = ${localDateToISO(iat)}\n` +
        `   jti = ${token.jti}, uid = ${token.uid}, ver = ${token.version}\n` +
        `   username = ${token.username}\n` +
        `   issuer = ${token.iss}\n`
    );
}


const AuthProvider = ({children}) => {
    function UserException(message) {
        this.message = message;
        this.name = "UserException";
    }

    const navigate = useNavigate();

    const checkTokens = (access, refresh) => {
        if (!isTokenValid(access) || !isTokenValid(refresh)) {
            throw new UserException("Invalid token format.");
        }
    }

    const idleTimeoutHandler = () => {
        navigate('/timeout');
    }

    const idleTimer = useIdleTimer({
        timeout: INACTIVITY_TIMEOUT_SEC * 1000,
        onIdle: idleTimeoutHandler,
        debounce: 500,
        startManually: true,
        crossTab: true
    });

    const isAccessUpdateNeeded = useRef(false);
    let lifetimeTimerId = null;
    let accessUpdateTimerId = null;

    function lifetimeHandler() {
        console.log(`lifetimeHandler() called`);
        navigate('/lifetime');
    }

    function accessUpdater() {
        console.log(`refreshHandler() called`);
        console.log(`refreshHandler(): isRefreshNeeded = ${isAccessUpdateNeeded.current}, id=${accessUpdateTimerId}`);
        if (!isAccessUpdateNeeded.current) {
            return;  // do nothing
        }

        const accessToken = getStorageValue(ACCESS_TOKEN_NAME);
        const refreshToken = getStorageValue(REFRESH_TOKEN_NAME);

        console.log(`refreshHandler(): access = ${accessToken}`);
        console.log(`refreshHandler(): refresh = ${refreshToken}`);

        apiRefresh(accessToken, refreshToken).then((result) => {
            const response = result.data;

            console.log(`handleRefresh(): success: data = ${JSON.stringify(response)}`);
            console.log(`handleRefresh(): response = ${JSON.stringify(response)}`);
            setStorageValue(ACCESS_TOKEN_NAME, response.access);
            dumpToken(jwt_decode(response.access));

            const oldAccess = jwt_decode(accessToken);
            const newAccess = jwt_decode(response.access);
            const accessDeltaMs = (newAccess.exp - oldAccess.exp) * 1000;

            console.log(`refreshHandler(): access delta = ${accessDeltaMs}`);
            accessUpdateTimerId = setTimeout(accessUpdater, accessDeltaMs);
        }).catch((e) => {
            console.log(e);
            isAccessUpdateNeeded.current = false;
            navigate('/error');
        });
    }

    const handleLogin = (username, password, resolve, reject) => {
        console.log(`handleLogin(): username=${username}, password=${password}`);
        resetAuth();
        apiLogin(username, password).then((result) => {
            const response = result.data;
            let access = null;
            let refresh = null;

            try {
                console.log(`handleLogin(): success: data = ${JSON.stringify(response)}`);

                access = jwt_decode(response.access);
                refresh = jwt_decode(response.refresh);

                console.log(`handleLogin(): success: access = ${JSON.stringify(access)}`);
                dumpToken(access);
                console.log(`token is valid? ${isTokenValid(access)}`);

                console.log(`handleLogin(): success: refresh = ${JSON.stringify(refresh)}`);
                dumpToken(refresh);
                console.log(`token is valid? ${isTokenValid(refresh)}`);

                checkTokens(access, refresh);
            }
            catch (e) {
                throw new UserException("");
            }
            setStorageValue(ACCESS_TOKEN_NAME, response.access);
            setStorageValue(REFRESH_TOKEN_NAME, response.refresh);
            setStorageValue(CUSTOMER_KEY_NAME, access.customer);

            const accessDeltaMs = (access.exp - access.iat - ACCESS_REFRESH_GAP_SEC) * 1000;
            const refreshDeltaMs = (refresh.exp - refresh.iat - 60) * 1000;

            console.log(`handleLogin(): access delta = ${accessDeltaMs}`);
            console.log(`handleLogin(): refresh delta = ${refreshDeltaMs}`);

            lifetimeTimerId = setTimeout(lifetimeHandler, refreshDeltaMs);
            accessUpdateTimerId = setTimeout(accessUpdater, accessDeltaMs);
            isAccessUpdateNeeded.current = true;

            idleTimer.start();
            resolve();
        }).catch((error) => {
            resetAuth();
            if (error instanceof UserException) {
                console.log(`custom exception thrown`);
                reject(500);  // 500 - Internal Server Error
            } else if (error.response) {
                console.log(`handleLogin(): error: code = ${error.response.status}`);
                console.log(`handleLogin(): error: data = ${JSON.stringify(error.response.data)}`);
                reject(error.response.status);
            } else if (error.request) {
                reject(null);
            }
        });
    }

    const handleLogout = () => {
        const accessToken = getStorageValue(ACCESS_TOKEN_NAME);
        const refreshToken = getStorageValue(REFRESH_TOKEN_NAME);

        console.log(`handleLogout(): access = ${accessToken}`);
        console.log(`handleLogout(): refresh = ${refreshToken}`);
        console.log(`handleLogout(): timer-id = ${accessUpdateTimerId}`);
        isAccessUpdateNeeded.current = false;
        clearTimeout(accessUpdateTimerId);
        accessUpdateTimerId = null;
        clearTimeout(lifetimeTimerId);
        lifetimeTimerId = null;
        idleTimer.pause();
        if (accessToken && refreshToken) {
            apiLogout(accessToken, refreshToken).then(() => {
            }).catch((e) => {
                console.log(e.toString())
            });
        }
        resetAuth();
        console.log(`handleLogout(): tokens was removed`);
    }

    const value = useMemo(
        () => ({
            lifetimeTimerId,
            accessUpdateTimerId,
            handleLogin,
            handleLogout
        }),
        [lifetimeTimerId, accessUpdateTimerId]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};


const ProtectedRoute = ({children}) => {
    const accessToken = getStorageValue(ACCESS_TOKEN_NAME);
    const refreshToken = getStorageValue(REFRESH_TOKEN_NAME);

    if (!accessToken || !refreshToken) {
        resetAuth();
        return <Navigate to="/login" replace/>;
    }
    return children;
};


export {AuthProvider, useAuth, ProtectedRoute, getAccessToken, getCustomerName};


/// eof ///
