import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {
    Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip
} from 'chart.js';
import {Line} from "react-chartjs-2";
import {getStorageValue, setStorageValue} from "./storage";
import {TRANSPORT_KEY_NAME} from "./Transport";
import {getAccessToken} from "./Auth";
import {apiTransport, apiRoutes, apiReportOverall, apiReportDaily, apiReportIntraday, apiReportStoppage} from "./api";
import {useNavigate} from "react-router-dom";
import {Spinner, Pager, calcChunkCount, TableHead, Rubles, formatShortDate, formatLongDate} from "./Misc";
import {ru} from 'date-fns/locale';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import UIkit from "uikit";
import {
    endOfMonth,
    endOfQuarter,
    startOfMonth,
    startOfQuarter,
    subMonths,
    isSameDay,
    isSameMonth,
    isSameYear,
    isBefore,
    differenceInDays,
    addDays,
    startOfToday,
    lastDayOfMonth,
    startOfDay,
    startOfYesterday,
    subMinutes,
    subDays,
    addMonths,
    isFuture,
    isPast
} from "date-fns";
import {css, styled} from "styled-components";
import {ROUTES_KEY_NAME} from "./AppRoutes";
import {PROFILE_KEY_NAME} from "./Profile";
import {VideoJS} from "./Player";


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);
ChartJS.defaults.font.family = "Manrope";


const calendarStyling = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid #404040;
  }
  .my-selected:hover:not([disabled]) { 
    border-color: grey;
    color: black;
  }
  .my-today { 
    font-weight: 600;
    color: black;
    background-color: #f0f0f0;
  }
  .my-outside { 
    color: #ededed;
  }
`;


const TabMenu = ({items, mode, setMode}) => {
    const selectedTabStyle = {borderBottom: "3px solid #1e87f0", color: "#1e87f0"};

    const switcher = (e) => {
        e.preventDefault();

        const newMode = parseInt(e.target.id);

        if ((newMode !== undefined) && !isNaN(newMode)) {
            setMode(newMode);
        }
    };

    return (<>
        <ul data-uk-tab onClick={switcher} className={"uk-margin-remove-top uk-margin-remove-bottom"}>
            {items.map((item, key) => {
                const name = (mode === key) ? "uk-active" : null;
                const styling = (mode === key) ? selectedTabStyle : null;

                return (
                    <li key={key} className={name}>
                        <a id={key} style={styling}>{item}</a>
                    </li>
                );
            })}
        </ul>
    </>);
}


const ReportLoadingStub = () => {
    return (<div className={"uk-inline"} style={{minHeight: "640px", width: "100%"}}>
        <div className="uk-position-center">
            <div style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                color: "blue"
            }}>
                <div data-uk-spinner="ratio: 1.6"></div>
            </div>
        </div>
    </div>);
}


const dropItemHeight = 32;
const HourItem = styled.div`
  margin: 6px;
  padding: 5px 2px 5px 2px;
  height: ${dropItemHeight}px;
  ${(props) => {
    if (props.$selected) {
      return css`
        background-color: #f0f0f0;
        font-weight: bold;
      `;
    }
    return css`
      &:hover {
        background-color: #1e87f0;
        color: white;
        cursor: pointer;
      }
    `;
  }};
`;
const Column = styled.div`
  display: inline-block;
  width: 137px;
  text-align: center;
`;
const VehicleItem = styled.div`
  margin: 6px 0 6px 0;
  padding: 5px 8px 5px 8px;
  height: ${dropItemHeight}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => {
    if (props.$selected) {
      return css`
        background-color: #f0f0f0;
      `;
    }
    return css`
      &:hover {
        background-color: #1e87f0;
        color: white;
        cursor: pointer;
      }
    `;
  }};
`;


const ReportHourPicker = ({hour, onApply}) => {
    const padNumber = (v) => {
        return (v < 10) ? ('0' + v) : `${v}`;
    };
    const getHours = (start, finish) => {
        let content = [];

        for (let i = start; i <= finish; ++i) {
            const v = padNumber(i);

            content.push(
                <HourItem id={i} key={i} $selected={(i === hour)}>
                    &nbsp;{v}:00&nbsp;&ndash;&nbsp;{v}:59&nbsp;
                </HourItem>
            );
        }
        return content;
    }
    const hourClick = (e) => {
        e.preventDefault();

        const selected = parseInt(e.target.id);

        if (isNaN(selected) || (hour === selected)) {
            return;
        }
        UIkit.drop(uiDropRef.current).hide(0);
        onApply(selected);
    };
    const uiDropRef = useRef(null);
    const num = padNumber(hour);

    return (
        <div className={"uk-width-auto"} style={{alignItems: "center"}}>
            <button className="uk-button uk-button-primary uk-button-small" style={{width: "125px"}}>
                {num}:00&nbsp;&ndash;&nbsp;{num}:59
            </button>
            <div
                className="uk-card-body uk-card-default uk-card-small uk-width-auto"
                data-uk-drop="mode: click; duration: 0; delay-hide: 0; pos: bottom-right"
                ref={uiDropRef}>
                <div>
                    <div
                        className={"uk-grid uk-grid-small uk-child-width-expand"}
                        onClick={(e) => hourClick(e)}
                    >
                        <div>
                            <Column>
                                <div>
                                    {getHours(0, 7)}
                                </div>
                            </Column>
                        </div>
                        <div>
                            <Column>
                                <div>
                                    {getHours(8, 15)}
                                </div>
                            </Column>
                        </div>
                        <div>
                            <Column>
                                <div>
                                    {getHours(16, 23)}
                                </div>
                            </Column>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const ReportDayPicker = ({day, onApply}) => {
    const [selected, setSelected] = useState(day);
    const [month, setMonth] = useState(startOfMonth(day));
    const [actual, setActual] = useState(selected);
    const applyClick = (e) => {
        e.preventDefault();
        UIkit.drop(uiDialogRef.current).hide(0);
        setActual(selected);
        onApply(selected);
    };
    const uiDialogRef = useRef(null);

    function setCurrent(v) {
        setSelected(v);
        setMonth(startOfMonth(v));
    }

    function onBeforeShow() {
        console.log(`ReportDayPicker: beforeshow called, selected=${selected}, actual=${actual}`);
        setCurrent(day);
    }

    useEffect(() => {
        UIkit.util.on(uiDialogRef.current, 'beforeshow', onBeforeShow);
        return () => {
            UIkit.util.off(uiDialogRef.current, 'beforeshow', onBeforeShow);
        }
    });
    return (
        <div className="uk-inline">
            <button className="uk-button uk-button-primary uk-button-small"
                    style={{width: "125px"}}>
                {day.toLocaleString('ru-RU', {
                    year: "numeric",
                    month: 'numeric',
                    day: 'numeric'
                })}
            </button>
            <div
                className="uk-card-body uk-card-default uk-card-small uk-width-auto"
                data-uk-drop="mode: click; duration: 0; delay-hide: 0; pos: bottom-right; target-x: .reportBasePoint"
                ref={uiDialogRef}
            >
                <div style={{fontFamily: "Manrope"}}>
                    <DayPicker
                        ISOWeek
                        showWeekNumber
                        fixedWeeks
                        showOutsideDays
                        locale={ru}
                        modifiersClassNames={{
                            selected: 'my-selected',
                            today: 'my-today',
                            outside: 'my-outside'
                        }}
                        fromDate={new Date(2023, 0, 1)}
                        toDate={subDays(new Date(), 1)}
                        required
                        mode="single"
                        selected={selected}
                        onSelect={setSelected}
                        month={month}
                        onMonthChange={setMonth}

                        styles={{
                            caption: {
                                height: "40px"
                            }
                        }}
                    />
                    <hr/>
                    <div style={{width: "100%", textAlign: "right"}}>
                        <button className="uk-button uk-button-primary uk-button-small"
                                onClick={(e) => applyClick(e)}
                                disabled={isSameDay(day, selected)}>
                            Применить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


const lookupVehicleName = (vehicle, vehicles) => {
    if (!vehicle || (vehicles?.length < 1)) {
        return null;
    }

    let obj = null;

    vehicles.every(item => {
        if (item.id === vehicle) {
            obj = item;
            return false;
        }
        return true;
    });
    if (!obj) {
        return null;
    }
    if (obj.model && obj.license) {
        return `${obj.model} (${obj.license})`;
    }
    if (obj.model) {
        return `${obj.model}`;
    }
    if (obj.license) {
        return `${obj.license}`;
    }
    return null;
};


const ReportVehiclePicker = ({vehicle, vehicles, onApply}) => {
    const [filterValue, setFilterValue] = useState('');
    const uiDialogSearchRef = useRef(null);

    const widthGap = 15;
    const itemPerColumn = 10;
    const maxColumns = 3;
    const actualVehicles = vehicles ? vehicles.filter(item => {
        if (!filterValue) {
            return true;
        }
        return item?.model.toLowerCase().includes(filterValue) ||
            item?.license.toLowerCase().includes(filterValue);
    }) : [];

    function calcPages(totalItems, itemPerColumn, maxColumns) {
        const predefinedWidths = [275, 240, 225];
        const itemPerPage = itemPerColumn * maxColumns;
        const colCount = vehicles ? Math.floor(vehicles.length / itemPerColumn) + ((vehicles.length % itemPerColumn) ? 1 : 0) : 1;
        const colWidth = predefinedWidths[Math.min(colCount, predefinedWidths.length) - 1];
        const pageCount = vehicles ? Math.floor(vehicles.length / itemPerPage) + ((vehicles.length % itemPerPage) ? 1 : 0) : 1;

        // console.log(`colCount = ${colCount}, pageCount = ${pageCount}, colWidth = ${colWidth}, w = ${predefinedWidths.length}`);
        return {
            width: colWidth,
            columns: Math.min(colCount, maxColumns),
            total: pageCount
        }
    }

    const pages = calcPages(vehicles?.length, itemPerColumn, maxColumns);

    const [curPage, setCurPage] = useState(0);  // zero-based
    const itemPerPage = itemPerColumn * maxColumns;
    const itemBase = itemPerPage * curPage;

    function onBeforeShow() {
        uiDialogSearchRef.current.autofocus = true;
        uiDialogSearchRef.current.value = '';
        setFilterValue('');

        let index = 0;

        if (vehicles) {
            vehicles.map((item, j) => {
                if (item.id === vehicle) {
                    index = Math.floor(j / itemPerPage);
                    console.log(`onBeforeShow : index=${index}`);
                }
            });
        }
        // console.log(`onBeforeShow : index=${index}`);
        setCurPage(index);
    }

    const uiDialogRef = useRef(null);

    const applyClick = (e) => {
        e.preventDefault();
        if (!e.target.id) {
            return;
        }

        const selected = Number(e.target.id);

        UIkit.drop(uiDialogRef.current).hide(0);
        if (selected !== vehicle) {
            onApply(selected);
        }

        // console.log(`applyClick = ${selected}`);
    }

    const getItemName = (item) => {
        if (item.model) {
            return `${item.model} (${item.license})`
        }
        return `${item.license}`;
    };

    const ItemColumn = ({index}) => {
        const right = itemBase + itemPerColumn * (index - 1);
        const left = itemBase + itemPerColumn * index;
        const content = actualVehicles.slice(right, left);

        return (
            <>
                {
                    content.map((item, key) => {
                        return (
                            <VehicleItem
                                key={key}
                                style={{width: pages.width + "px"}}
                                id={item.id}
                                $selected={(item.id === vehicle)}
                            >
                                {getItemName(item)}
                            </VehicleItem>
                        );
                    })
                }
            </>
        );
    };

    useEffect(() => {
        UIkit.util.on(uiDialogRef.current, 'beforeshow', onBeforeShow);
        return () => {
            UIkit.util.off(uiDialogRef.current, 'beforeshow', onBeforeShow);
        }
    });
    // console.log(`cols=${JSON.stringify(cols)}, count=${vehicles?.length}`);
    // console.log(`itemBase=${itemBase}, curPage=${curPage}`);
    return (
        <div className="uk-inline">
            <button className="uk-button uk-button-primary uk-button-small">
                {lookupVehicleName(vehicle, vehicles) || "Выбрать"}
            </button>
            <div
                className="uk-card-body uk-card-default uk-card-small uk-width-auto"
                data-uk-drop="mode: click; duration: 0; delay-hide: 0; pos: bottom-right; target-x: .reportBasePoint"
                ref={uiDialogRef}
            >
                <form className="uk-search uk-search-default" style={{width: "100%"}}>
                    <span data-uk-search-icon></span>
                    <input className="uk-search-input"
                           type="search"
                           placeholder="Фильтр по ТС..."
                           maxLength="64"
                           ref={uiDialogSearchRef}
                           onChange={e => {
                               setCurPage(0);
                               setFilterValue(e.target.value.toLowerCase())
                           }}></input>
                </form>
                <div className="uk-margin-small-top">
                    <div style={{display: "inline-block"}}>
                        <div>
                            {!actualVehicles.length && <div className={"uk-margin-small-top"}>
                                Не найдено ТС по заданному критерию...
                            </div>}
                            {actualVehicles && <div>
                                <div className="uk-grid uk-grid-small uk-child-width-auto"
                                     style={{width: ((pages.width + widthGap) * pages.columns) + "px"}}
                                     onClick={(e) => applyClick(e)}
                                >
                                    <div style={{
                                        width: (pages.width + widthGap) + "px",
                                        height: (actualVehicles.length > itemPerColumn) ? ((dropItemHeight + 6) * 10 + "px") : null,
                                        position: "relative"
                                    }}>
                                        <ItemColumn index={1}/>
                                    </div>
                                    {(pages.columns > 1) && <div style={{width: (pages.width + widthGap) + "px"}}>
                                        <ItemColumn index={2}/>
                                    </div>}
                                    {(pages.columns > 2) && <div style={{width: (pages.width + widthGap) + "px"}}>
                                        <ItemColumn index={3}/>
                                    </div>}
                                </div>
                                {((pages.total > 1) && (actualVehicles.length >= (maxColumns * itemPerColumn))) &&
                                    <div className={"uk-margin-small-top"}>
                                        <Pager curPage={curPage} totalPages={pages.total} setCurPage={setCurPage}/>
                                    </div>}
                            </div>}
                        </div>
                    </div>
                </div>
                <hr className="uk-margin-small-top"/>
                <div>
                    Всего <b>{vehicles?.length || 0}</b> ТС.
                </div>
            </div>
        </div>
    );
}


const lookupRouteName = (route, routes) => {
    if ((route === null) || (routes?.length < 1)) {
        return "Все ТС парка";
    }

    let obj = null;

    routes.every(item => {
        if (item.id === route) {
            obj = item;
            return false;
        }
        return true;
    });
    if (!obj) {
        return null;
    }
    if (obj.title) {
        return `${obj.title}`;
    }
    return null;
};


const toNumeric = (value, words) => {
    value = Math.abs(value) % 100;

    const n = value % 10;

    if ((value > 10) && (value < 20)) {
        return words[2];
    }
    if ((n > 1) && (n < 5)) {
        return words[1];
    }
    if (n === 1) {
        return words[0];
    }
    return words[2];
};


const ReportRoutePicker = ({route, routes, onApply}) => {
    const [filterValue, setFilterValue] = useState('');
    const uiDialogSearchRef = useRef(null);

    const widthGap = 15;
    const itemPerColumn = 10;
    const maxColumns = 3;
    const actualRoutes = [null].concat(routes ? routes.filter(item => {
        // console.log(`${JSON.stringify(item)}`);
        if (!filterValue) {
            return true;
        }
        return item?.title.toLowerCase().includes(filterValue);
    }) : []);

    function calcPages(totalItems, itemPerColumn, maxColumns) {
        const predefinedWidths = [275, 240, 225];
        const itemPerPage = itemPerColumn * maxColumns;
        const colCount = calcChunkCount(routes, itemPerColumn);
        const colWidth = predefinedWidths[Math.min(colCount, predefinedWidths.length) - 1];
        const pageCount = calcChunkCount(routes, itemPerPage);

        // console.log(`colCount = ${colCount}, pageCount = ${pageCount}, colWidth = ${colWidth}, w = ${predefinedWidths.length}`);
        return {
            width: colWidth,
            columns: Math.min(colCount, maxColumns),
            total: pageCount
        }
    }

    const pages = calcPages(routes?.length, itemPerColumn, maxColumns);

    const [curPage, setCurPage] = useState(0);  // zero-based
    const itemPerPage = itemPerColumn * maxColumns;
    const itemBase = itemPerPage * curPage;

    function onBeforeShow() {
        uiDialogSearchRef.current.autofocus = true;
        uiDialogSearchRef.current.value = '';
        setFilterValue('');

        let index = 0;

        if (routes) {
            routes.map((item, j) => {
                if (item.id === route) {
                    index = Math.floor(j / itemPerPage);
                    // console.log(`onBeforeShow : index=${index}`);
                }
            });
        }
        // console.log(`onBeforeShow : index=${index}`);
        setCurPage(index);
    }

    const uiDialogRef = useRef(null);

    const applyClick = (e) => {
        e.preventDefault();
        if (!e.target.id) {
            return;
        }

        const selected = Number(e.target.id);

        UIkit.drop(uiDialogRef.current).hide(0);
        onApply((selected === -1) ? null : selected);

        // console.log(`applyClick = ${selected}`);
    }

    const ItemColumn = ({index}) => {
        const getItemName = (item) => {
            if (item === null) {
                return `Все ТС парка`
            }
            return `${item.title}`;
        };

        const right = itemBase + itemPerColumn * (index - 1);
        const left = itemBase + itemPerColumn * index;
        const content = actualRoutes.slice(right, left);

        return (
            <>
                {content.map((item, key) => {
                    const flag = ((item?.id === route) || (item === route));
                    let styling = {
                        width: pages.width + "px"
                    };

                    if (item === null) {
                        styling.fontWeight = "bold";
                    }
                    return (
                        <VehicleItem key={key} style={styling} id={item?.id || -1} $selected={flag}>
                            {getItemName(item)}
                        </VehicleItem>
                    );
                })}
            </>
        );
    };

    useEffect(() => {
        UIkit.util.on(uiDialogRef.current, 'beforeshow', onBeforeShow);
        return () => {
            UIkit.util.off(uiDialogRef.current, 'beforeshow', onBeforeShow);
        }
    });
    return (
        <div className="uk-inline">
            <button className="uk-button uk-button-primary uk-button-small" disabled={(routes?.length < 1)}>
                {lookupRouteName(route, routes) || "Выбрать"}
            </button>
            <div
                className="uk-card-body uk-card-default uk-card-small uk-width-auto"
                data-uk-drop="mode: click; duration: 0; delay-hide: 0; pos: bottom-right; target-x: .reportBasePoint"
                ref={uiDialogRef}
            >
                <form className="uk-search uk-search-default" style={{width: "100%"}}>
                    <span data-uk-search-icon></span>
                    <input className="uk-search-input"
                           type="search"
                           placeholder="Фильтр по маршрутам..."
                           maxLength="64"
                           ref={uiDialogSearchRef}
                           onChange={e => {
                               setCurPage(0);
                               setFilterValue(e.target.value.toLowerCase())
                           }}></input>
                </form>
                <div className="uk-margin-small-top">
                    <div style={{display: "inline-block"}}>
                        <div>
                            {!actualRoutes.length &&
                                <div className={"uk-margin-small-top"}>
                                    Не найдено маршрутов по заданному критерию...
                                </div>
                            }
                            {actualRoutes &&
                                <div>
                                    <div className="uk-grid uk-grid-small uk-child-width-auto"
                                         style={{width: ((pages.width + widthGap) * pages.columns) + "px"}}
                                         onClick={(e) => applyClick(e)}
                                    >
                                        <div style={{
                                            width: (pages.width + widthGap) + "px",
                                            height: (actualRoutes.length > itemPerColumn) ? ((dropItemHeight + 6) * 10 + "px") : null,
                                            position: "relative"
                                        }}>
                                            <ItemColumn index={1}/>
                                        </div>
                                        {(pages.columns > 1) &&
                                            <div style={{width: (pages.width + widthGap) + "px"}}>
                                                <ItemColumn index={2}/>
                                            </div>
                                        }
                                        {(pages.columns > 2) &&
                                            <div style={{width: (pages.width + widthGap) + "px"}}>
                                                <ItemColumn index={3}/>
                                            </div>
                                        }
                                    </div>
                                    {((pages.total > 1) && (actualRoutes.length >= (maxColumns * itemPerColumn))) &&
                                        <div className={"uk-margin-small-top"}>
                                            <Pager curPage={curPage} totalPages={pages.total} setCurPage={setCurPage}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <hr className="uk-margin-small-top"/>
                <div>
                    {(routes?.length > 0) &&
                        <div>
                            Всего <b>{routes?.length || 0}</b> {toNumeric(routes?.length, ['маршрут', 'маршрута', 'маршрутов'])}.
                        </div>
                    }
                    {!routes?.length &&
                        <div>
                            Ни одного маршрута не определено.
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}


const getMonthName = (m) => {
    return m.toLocaleString('ru-RU', {month: 'long'});
};


const getDateRangeString = (fromMonth, toMonth) => {
    let title = '';
    const endOfPeriod = subDays(toMonth, 1);

    if (isSameMonth(fromMonth, endOfPeriod)) {
        title = getMonthName(endOfPeriod) + '\u00a0' + endOfPeriod.getFullYear();
    } else if (isSameYear(fromMonth, endOfPeriod)) {
        title = getMonthName(fromMonth) + '\u2013' + getMonthName(endOfPeriod) + '\u00a0' + endOfPeriod.getFullYear();
    } else {
        title = getMonthName(fromMonth) + '\u00a0' + fromMonth.getFullYear() + '\u00a0\u2013\u00a0' + getMonthName(endOfPeriod) + '\u00a0' + endOfPeriod.getFullYear();
    }
    return title;
};


const ReportMonthRangePicker = ({selectedRange, availableRange, onApply}) => {
    const [leftMonth, setLeftMonth] = useState(selectedRange.fromMonth);
    const [rightMonth, setRightMonth] = useState(subDays(selectedRange.toMonth, 1));

    const uiDialogRef = useRef(null);
    const applyClick = (e) => {
        e.preventDefault();
        UIkit.drop(uiDialogRef.current).hide(0);

        const left = startOfMonth(leftMonth);
        const right = addDays(lastDayOfMonth(rightMonth), 1);

        // console.log(`ReportMonthRangePicker: left=${left}, right=${right}`);
        onApply(left, right);
    };

    function setCurrent(v) {
        setLeftMonth(v.fromMonth);
        setRightMonth(subDays(v.toMonth, 1));
    }

    function onBeforeShow() {
        setCurrent(selectedRange);
    }

    useEffect(() => {
        UIkit.util.on(uiDialogRef.current, 'beforeshow', onBeforeShow);
        return () => {
            UIkit.util.off(uiDialogRef.current, 'beforeshow', onBeforeShow);
        }
    });
    return (
        <>
            <div className="uk-inline">
                <button className="uk-button uk-button-primary uk-button-small">
                    {getDateRangeString(selectedRange.fromMonth, selectedRange.toMonth)}
                </button>
                <div
                    className="uk-card-body uk-card-default uk-card-small uk-width-auto"
                    data-uk-drop="mode: click; duration: 0; delay-hide: 0; pos: bottom-right; target-x: .reportBasePoint"
                    ref={uiDialogRef}
                >
                    <div style={{width: "696px", fontFamily: "Manrope"}}>
                        <div style={{display: "inline-block"}}>
                            <DayPicker
                                ISOWeek
                                showWeekNumber
                                fixedWeeks
                                locale={ru}
                                modifiersClassNames={{
                                    selected: 'my-selected',
                                    today: 'my-today',
                                    outside: 'my-outside'
                                }}
                                fromDate={availableRange.fromDate}
                                toDate={rightMonth}

                                month={leftMonth}
                                onMonthChange={setLeftMonth}

                                styles={{
                                    caption: {
                                        height: "40px"
                                    }
                                }}
                            />
                        </div>
                        <div style={{display: "inline-block"}}>
                            <DayPicker
                                ISOWeek
                                showWeekNumber
                                fixedWeeks
                                locale={ru}
                                modifiersClassNames={{
                                    selected: 'my-selected',
                                    today: 'my-today',
                                    outside: 'my-outside'
                                }}
                                fromDate={leftMonth}
                                toDate={availableRange.toDate}

                                month={rightMonth}
                                onMonthChange={setRightMonth}

                                styles={{
                                    caption: {
                                        height: "40px"
                                    }
                                }}
                            />
                        </div>
                        <hr/>
                        <div style={{width: "100%", textAlign: "right"}}>
                            <button
                                className="uk-button uk-button-default uk-button-small"
                                onClick={(e) => {
                                    e.preventDefault();

                                    const prev = subMonths(new Date(), 3);
                                    const start = startOfQuarter(prev);
                                    const end = endOfQuarter(prev);

                                    setLeftMonth(start);
                                    setRightMonth(end);
                                }}
                            >
                                Предыдущий квартал
                            </button>
                            <button
                                className="uk-button uk-button-default uk-button-small uk-margin-left"
                                onClick={(e) => {
                                    e.preventDefault();

                                    const now = new Date();
                                    const start = startOfQuarter(now);

                                    setLeftMonth(start);
                                    setRightMonth(now);
                                }}
                            >
                                Этот квартал
                            </button>
                            <button
                                className="uk-button uk-button-default uk-button-small uk-margin-left"
                                onClick={(e) => {
                                    e.preventDefault();

                                    const now = new Date();
                                    const start = startOfMonth(now);

                                    setLeftMonth(start);
                                    setRightMonth(now);
                                }}
                            >
                                Этот месяц
                            </button>
                            <button
                                className="uk-button uk-button-primary uk-button-small uk-margin-left"
                                onClick={(e) => applyClick(e)}
                                disabled={
                                    isSameMonth(selectedRange.fromMonth, leftMonth) &&
                                    isSameMonth(subDays(selectedRange.toMonth, 1), rightMonth)
                                }
                            >
                                Применить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


const SelectDateRange = ({selected, range, onApply}) => {
    const [leftDate, setLeftDate] = useState(selected.fromDate);
    const [rightDate, setRightDate] = useState(subMinutes(startOfDay(selected.toDate), 1));
    const [leftMonth, setLeftMonth] = useState(startOfMonth(leftDate));
    const [rightMonth, setRightMonth] = useState(startOfMonth(rightDate));
    const prevMonth = subMonths(new Date(), 1);

    return (
        <div style={{width: "696px", fontFamily: "Manrope"}}>
            <div style={{display: "inline-block"}}>
                <DayPicker
                    ISOWeek
                    showWeekNumber
                    fixedWeeks
                    locale={ru}
                    mode="single"
                    modifiersClassNames={{
                        selected: 'my-selected',
                        today: 'my-today',
                        outside: 'my-outside'
                    }}
                    fromDate={range.fromDate}
                    toDate={rightDate}
                    selected={leftDate}
                    onSelect={setLeftDate}
                    required

                    month={leftMonth}
                    onMonthChange={setLeftMonth}

                    styles={{
                        caption: {
                            height: "40px"
                        }
                    }}
                />
            </div>
            <div style={{display: "inline-block"}}>
                <DayPicker
                    ISOWeek
                    showWeekNumber
                    fixedWeeks
                    locale={ru}
                    mode="single"
                    modifiersClassNames={{
                        selected: 'my-selected',
                        today: 'my-today',
                        outside: 'my-outside'
                    }}
                    fromDate={leftDate}
                    toDate={subMinutes(range.toDate, 1)}
                    selected={rightDate}
                    onSelect={setRightDate}
                    required

                    month={rightMonth}
                    onMonthChange={setRightMonth}

                    styles={{
                        caption: {
                            height: "40px"
                        }
                    }}
                />
            </div>
            <hr/>
            <div style={{width: "100%", textAlign: "right"}}>
                <button className="uk-button uk-button-default uk-button-small"
                        onClick={(e) => {
                            e.preventDefault();

                            const start = startOfMonth(prevMonth);
                            const end = endOfMonth(prevMonth);

                            setLeftDate(start);
                            setRightDate(end);
                            setLeftMonth(start);
                            setRightMonth(end);
                        }} disabled={isBefore(prevMonth, range.fromDate)}>
                    Предыдущий месяц
                </button>
                <button className="uk-button uk-button-default uk-button-small uk-margin-left"
                        onClick={(e) => {
                            e.preventDefault();

                            const now = new Date();
                            const fromDate = startOfMonth(now);
                            const toDate = subMinutes(range.toDate, 1);

                            setLeftDate(fromDate);
                            setRightDate(toDate);
                            setLeftMonth(fromDate);
                            setRightMonth(toDate);
                        }}>
                    Этот месяц
                </button>
                <button className="uk-button uk-button-primary uk-button-small uk-margin-left"
                        onClick={(e) => {
                            e.preventDefault();
                            onApply(leftDate, startOfDay(addDays(rightDate, 1)));
                        }}
                        disabled={isSameDay(leftDate, selected.fromDate) && isSameDay(addDays(rightDate, 1), selected.toDate)}
                >
                    Применить
                </button>
            </div>
        </div>
    );
}


const ReportDateRangePicker = ({selectedRange, availableRange, onApply}) => {
    const uiDialogRef = useRef(null);

    const formatDate = (fromDate, toDate) => {
        const right = subMinutes(toDate, 1);
        let result = fromDate.toLocaleString('ru-RU', {
            year: "numeric",
            month: 'numeric',
            day: 'numeric'
        });

        if (!isSameDay(fromDate, right)) {
            result += '\u00a0\u2012\u00a0' + right.toLocaleString('ru-RU', {
                year: "numeric",
                month: 'numeric',
                day: 'numeric'
            });
        }
        return result;
    }

    const applyClick = (left, right) => {
        UIkit.drop(uiDialogRef.current).hide(0);
        onApply({fromDate: left, toDate: right});
    };

    return (
        <>
            <div className="uk-inline">
                <button className="uk-button uk-button-primary uk-button-small">
                    {formatDate(selectedRange.fromDate, selectedRange.toDate)}
                </button>
                <div
                    className="uk-card-body uk-card-default uk-card-small uk-width-auto"
                    data-uk-drop="mode: click; duration: 0; delay-hide: 0; pos: bottom-right; target-x: .reportBasePoint"
                    ref={uiDialogRef}>
                    <SelectDateRange selected={selectedRange} range={availableRange} onApply={applyClick}/>
                </div>
            </div>
        </>
    );
}


const ReportRouteOrVehiclePicker = ({selected, routes, vehicles, onApply}) => {
    const [filterValue, setFilterValue] = useState('');
    const uiDialogSearchRef = useRef(null);

    const widthGap = 15;
    const itemPerColumn = 10;
    const maxColumns = 3;
    const actualVehicles = vehicles ? vehicles.filter(item => {
        if (!filterValue) {
            return true;
        }
        return item?.model.toLowerCase().includes(filterValue) ||
            item?.license.toLowerCase().includes(filterValue);
    }) : [];
    const actualRoutes = [null].concat(routes ? routes.filter(item => {
        // console.log(`${JSON.stringify(item)}`);
        if (!filterValue) {
            return true;
        }
        return item?.title.toLowerCase().includes(filterValue);
    }) : []);

    function calcPages(totalItems, itemPerColumn, maxColumns) {
        const predefinedWidths = [275, 240, 225];
        const itemPerPage = itemPerColumn * maxColumns;
        const colCount = calcChunkCount(vehicles, itemPerColumn);
        const colWidth = predefinedWidths[Math.min(colCount, predefinedWidths.length) - 1];
        const pageCount = calcChunkCount(vehicles, itemPerPage);

        // console.log(`colCount = ${colCount}, pageCount = ${pageCount}, colWidth = ${colWidth}, w = ${predefinedWidths.length}`);
        return {
            width: colWidth,
            columns: Math.min(colCount, maxColumns),
            total: pageCount
        }
    }

    const pages = calcPages(Math.max(routes?.length, vehicles?.length), itemPerColumn, maxColumns);

    const [curRoutePage, setCurRoutePage] = useState(0);  // zero-based
    const [curVehiclePage, setCurVehiclePage] = useState(0);  // zero-based
    const itemPerPage = itemPerColumn * maxColumns;
    const itemRouteBase = itemPerPage * curRoutePage;
    const itemVehicleBase = itemPerPage * curVehiclePage;

    const vehicle = (selected?.type === 1) ? selected.id : null;
    const route = (selected?.type === 0) ? selected.id : null;

    const dialogModes = ['Маршруты', 'ТС']
    const [mode, setMode] = useState(0);

    const dialogHeight = (() => {
        const count = Math.max(actualRoutes.length, actualVehicles.length);
        let h = (dropItemHeight + 6) * Math.min(count, itemPerColumn);

        if (pages.total > 1) {
            h += 41;
        }
        return h;
    })();

    function onBeforeShow() {
        uiDialogSearchRef.current.autofocus = true;
        uiDialogSearchRef.current.value = '';
        setFilterValue('');

        let index = 0;

        vehicles?.map((item, j) => {
            if (item.id === vehicle) {
                index = Math.floor(j / itemPerPage);
                // console.log(`onBeforeShow [1] : index=${index}`);
            }
        });
        setCurVehiclePage(index);

        index = 0;
        routes?.map((item, j) => {
            if (item.id === vehicle) {
                index = Math.floor(j / itemPerPage);
                // console.log(`onBeforeShow [2] : index=${index}`);
            }
        });
        setCurRoutePage(index);
        setMode(selected ? selected.type : 0);
    }

    const uiDialogRef = useRef(null);

    const applyRouteClick = (e) => {
        e.preventDefault();
        if (!e.target.id) {
            return;
        }

        const selected = Number(e.target.id);

        UIkit.drop(uiDialogRef.current).hide(0);
        onApply({type: 0, id: (selected === -1) ? null : selected});
    }

    const applyVehicleClick = (e) => {
        e.preventDefault();
        if (!e.target.id) {
            return;
        }

        const selected = Number(e.target.id);

        UIkit.drop(uiDialogRef.current).hide(0);
        onApply({type: 1, id: selected});
    }

    const VehicleItemColumn = ({index}) => {
        const getItemName = (item) => {
            if (item.model) {
                return `${item.model} (${item.license})`
            }
            return `${item.license}`;
        };

        const right = itemVehicleBase + itemPerColumn * (index - 1);
        const left = itemVehicleBase + itemPerColumn * index;
        const content = actualVehicles.slice(right, left);

        return (
            <>
                {
                    content.map((item, key) => {
                        const flag = (selected?.type === 1) && (item.id === vehicle);

                        return (
                            <VehicleItem key={key} style={{width: pages.width + "px"}} id={item.id} $selected={flag}>
                                {getItemName(item)}
                            </VehicleItem>
                        );
                    })
                }
            </>
        );
    };

    const RouteItemColumn = ({index}) => {
        const getItemName = (item) => {
            if (item === null) {
                return `Все ТС парка`
            }
            return `${item.title}`;
        };

        const right = itemRouteBase + itemPerColumn * (index - 1);
        const left = itemRouteBase + itemPerColumn * index;
        const content = actualRoutes.slice(right, left);

        return (
            <>
                {
                    content.map((item, key) => {
                        const flag = (selected?.type === 0) && ((item?.id === route) || (item === route));
                        let styling = {
                            width: pages.width + "px"
                        };

                        if (item === null) {
                            styling.fontWeight = "bold";
                        }
                        return (
                            <VehicleItem key={key} style={styling} id={item?.id || -1} $selected={flag}>
                                {getItemName(item)}
                            </VehicleItem>
                        );
                    })
                }
            </>
        );
    };

    useEffect(() => {
        UIkit.util.on(uiDialogRef.current, 'beforeshow', onBeforeShow);
        return () => {
            UIkit.util.off(uiDialogRef.current, 'beforeshow', onBeforeShow);
        }
    });
    return (
        <div className="uk-inline">
            <button className="uk-button uk-button-primary uk-button-small">
                {((selected?.type === 0) ? lookupRouteName(selected?.id, routes) : lookupVehicleName(selected?.id, vehicles)) || "Выбрать"}
            </button>
            <div
                className="uk-card-body uk-card-default uk-card-small uk-width-auto"
                data-uk-drop="mode: click; duration: 0; delay-hide: 0; pos: bottom-right; target-x: .reportBasePoint"
                ref={uiDialogRef}
            >
                <form className="uk-search uk-search-default uk-margin-small-bottom" style={{width: "100%"}}>
                    <span data-uk-search-icon></span>
                    <input className="uk-search-input"
                           type="search"
                           placeholder="Фильтр по ТС..."
                           maxLength="64"
                           ref={uiDialogSearchRef}
                           onChange={e => {
                               setCurRoutePage(0);
                               setFilterValue(e.target.value.toLowerCase())
                           }}></input>
                </form>
                <TabMenu items={dialogModes} mode={mode} setMode={setMode}/>
                {(mode === 0) &&
                    <div>
                        <div className="uk-margin-small-top">
                            <div style={{display: "inline-block"}}>
                                <div>
                                    {!actualRoutes.length &&
                                        <div className={"uk-margin-small-top"}>
                                            Не найдено маршрутов по заданному критерию...
                                        </div>
                                    }
                                    {actualRoutes &&
                                        <div>
                                            <div className="uk-grid uk-grid-small uk-child-width-auto"
                                                 style={{width: ((pages.width + widthGap) * pages.columns) + "px"}}
                                                 onClick={(e) => applyRouteClick(e)}
                                            >
                                                <div style={{
                                                    width: (pages.width + widthGap) + "px",
                                                    height: dialogHeight + "px",
                                                    position: "relative"
                                                }}>
                                                    <RouteItemColumn index={1}/>
                                                </div>
                                                {(pages.columns > 1) &&
                                                    <div style={{width: (pages.width + widthGap) + "px"}}>
                                                        <RouteItemColumn index={2}/>
                                                    </div>
                                                }
                                                {(pages.columns > 2) &&
                                                    <div style={{width: (pages.width + widthGap) + "px"}}>
                                                        <RouteItemColumn index={3}/>
                                                    </div>
                                                }
                                            </div>
                                            {((pages.total > 1) && (actualRoutes.length >= (maxColumns * itemPerColumn))) &&
                                                <div className={"uk-margin-small-top"}>
                                                    <Pager
                                                        curPage={curRoutePage}
                                                        totalPages={pages.total}
                                                        setCurPage={setCurRoutePage}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr className="uk-margin-small-top"/>
                        <div>
                            {(routes?.length > 0) &&
                                <div>
                                    Всего <b>{routes?.length || 0}</b> {toNumeric(routes?.length, ['маршрут', 'маршрута', 'маршрутов'])}.
                                </div>
                            }
                            {!routes?.length &&
                                <div>
                                    Ни одного маршрута не определено.
                                </div>
                            }
                        </div>
                    </div>
                }
                {(mode === 1) &&
                    <div>
                        <div className="uk-margin-small-top">
                            <div style={{display: "inline-block"}}>
                                <div>
                                    {!actualVehicles.length &&
                                        <div className={"uk-margin-small-top"}>
                                            Не найдено ТС по заданному критерию...
                                        </div>
                                    }
                                    {actualVehicles &&
                                        <div>
                                            <div className="uk-grid uk-grid-small uk-child-width-auto"
                                                 style={{width: ((pages.width + widthGap) * pages.columns) + "px"}}
                                                 onClick={(e) => applyVehicleClick(e)}
                                            >
                                                <div style={{
                                                    width: (pages.width + widthGap) + "px",
                                                    height: (actualVehicles.length > itemPerColumn) ? ((dropItemHeight + 6) * 10 + "px") : null,
                                                    position: "relative"
                                                }}>
                                                    <VehicleItemColumn index={1}/>
                                                </div>
                                                {(pages.columns > 1) &&
                                                    <div style={{width: (pages.width + widthGap) + "px"}}>
                                                        <VehicleItemColumn index={2}/>
                                                    </div>
                                                }
                                                {(pages.columns > 2) &&
                                                    <div style={{width: (pages.width + widthGap) + "px"}}>
                                                        <VehicleItemColumn index={3}/>
                                                    </div>
                                                }
                                            </div>
                                            {((pages.total > 1) && (actualVehicles.length >= (maxColumns * itemPerColumn))) &&
                                                <div className={"uk-margin-small-top"}>
                                                    <Pager
                                                        curPage={curVehiclePage}
                                                        totalPages={pages.total}
                                                        setCurPage={setCurVehiclePage}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <hr className="uk-margin-small-top"/>
                        <div>
                            Всего <b>{vehicles?.length || 0}</b> ТС.
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}


const ReportVehicleTable = ({vehicles, routes, data, costCache, onSetPrice, onVehicleClick}) => {
    const [edit, setEdit] = useState(null);

    const tableClickHandler = (e, flag) => {
        e.preventDefault();

        const rowId = Number(e.target.parentNode.dataset.rowid);

        // console.log(`tableClickHandler=${rowId}`);
        if (!flag) {
            onVehicleClick(rowId);
        } else if (!edit) {
            setEdit(rowId);
        }
    };

    const toRubles = (v) => {
        let s = v || "-";

        return `${s}\u00a0\u20bd`;
    };

    const EditableCell = ({value, row}) => {
        // console.log(`edit=${edit}, value=${value} row=${row}`);
        if (edit !== Number(row)) {
            return (<>{toRubles(value)}</>);
        }
        return (
            <>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    setEdit(null);
                    onSetPrice(Number(e.target.parentNode.parentNode.dataset.rowid), Number(e.target.price.value));
                }} onBlur={(e) => {
                    e.preventDefault();
                    setEdit(null);
                }}>
                    <input
                        className={"uk-input uk-form-small uk-form-width-small uk-margin-remove-top uk-margin-remove-bottom"}
                        type="number" defaultValue={value} autoFocus={true} name="price"/>
                </form>
            </>
        );
    }

    const [curPage, setCurPage] = useState(0);
    const itemsPerPage = 10;
    const totalPages = calcChunkCount(vehicles, itemsPerPage);
    const baseIndex = curPage * itemsPerPage;

    return (
        <>
            <table className="uk-table uk-table-striped uk-table-hover uk-table-small"
                   style={{fontSize: "13px"}}>
                <thead>
                <tr>
                    <th style={{width: "320px"}}><TableHead>ТС</TableHead></th>
                    <th style={{width: "165px"}}><TableHead>Перевезено<br/>пассажиров</TableHead></th>
                    <th style={{width: "165px"}}><TableHead>Маршрут</TableHead></th>
                    <th style={{width: "165px"}}>
                        <TableHead>Стоимость<br/>проезда</TableHead>
                        <div className="uk-inline" style={{verticalAlign: "baseline"}}>
                                                            <span className="uk-margin-small-left"
                                                                  data-uk-icon="icon: info; ratio: 0.8"
                                                                  style={{color: "black"}}></span>
                            <div className="uk-card-small uk-card-body uk-card-default"
                                 uk-drop="mode: hover; duration: 0; delay-hide: 0"
                                 style={{textTransform: "none"}}>
                                Для тех ТС, для которых не задан маршрут, можно
                                отредактировать стоимость проезда вручную, сделав
                                двойной клик в данном столбце напротив нужного ТС.
                                Значение стоимости проезда будет учтено в отчёте и
                                сохранено до конца текущего сеанса работы.
                            </div>
                        </div>
                    </th>
                    <th style={{width: "165px"}}><TableHead>Выручка</TableHead></th>
                </tr>
                </thead>
                <tbody>
                {
                    vehicles?.slice(baseIndex, baseIndex + itemsPerPage).map(item => {
                        const itemRoute = routes?.routes.find(v => (v.id === item?.route)) || null;
                        const count = data[item.id]?.reduce((acc, v) => {
                            return acc + v
                        }, 0);
                        let cost = null;

                        if (item?.id in costCache) {
                            cost = costCache[item.id];
                            // console.log(`cost found -> ${cost}`);
                        }
                        return (
                            <tr key={item?.id} data-rowid={item?.id}>
                                <td style={{cursor: "pointer", verticalAlign: "middle"}}
                                    onClick={(e) => tableClickHandler(e, false)}>
                                    {item?.model} ({item?.license})
                                </td>
                                <td style={{verticalAlign: "middle"}}>{count || "-"}</td>
                                <td>{itemRoute?.title || "-"}</td>
                                {itemRoute ?
                                    <td><Rubles value={itemRoute.cost}/></td> :
                                    <td style={{cursor: "pointer", verticalAlign: "middle"}}
                                        onClick={(e) => tableClickHandler(e, true)}>
                                        <EditableCell value={cost} row={item?.id}/>
                                    </td>
                                }
                                <td style={{verticalAlign: "middle"}}>
                                    <Rubles
                                        value={itemRoute?.cost ?
                                            (itemRoute.cost * count) :
                                            (cost ? (cost * count) : "-")}/>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            {(totalPages > 1) &&
                <Pager curPage={curPage} setCurPage={setCurPage} totalPages={totalPages}/>
            }
        </>
    );
}


const DailyChartEx = ({title, fromDate, toDate, dataset}) => {
    const composeDataset = (data) => {
        let daily = null;

        for (const key in data) {
            const item = data[key]
            let count = 0;

            if (!daily) {
                daily = [];
                for (const v of item) {
                    daily.push(v);
                }
                continue;
            }
            for (const v of item) {
                if (daily[count] === undefined) {
                    if (v !== undefined && v > 0) {
                        daily[count] = v;
                    }
                } else if (v !== undefined) {
                    daily[count] += v;
                }
                ++count;
            }
        }
        return daily;
    };

    const days = differenceInDays(toDate, fromDate);
    const labels = Array.from({length: days}, (v, k) => {
        const current = addDays(fromDate, k);
        let label = '';

        if (isSameYear(fromDate, toDate)) {
            label = current.toLocaleString('ru-RU', {
                month: 'numeric',
                day: 'numeric'
            });
        } else {
            label = current.toLocaleString('ru-RU', {
                year: "numeric",
                month: 'numeric',
                day: 'numeric'
            });
        }
        return label;
    });
    const data = {
        labels,
        datasets: [
            {
                label: 'Перевезено пассажиров',
                data: composeDataset(dataset),
                spanGaps: false,
                borderColor: '#1e87f0',
                backgroundColor: 'rgb(255, 255, 255)'
            }
        ]
    };
    const options = {
        animation: false,
        responsive: true,
        normalized: true,
        scales: {
            y: {
                min: 0,
                // max: 2500,
                ticks: {
                    color: 'rgb(80, 80, 80)',
                    font: {
                        size: '11px'
                    }
                },
                grid: {
                    color: 'rgb(240, 240, 240)'
                },
                border: {
                    color: 'rgb(128, 128, 128)'
                }
            },
            x: {
                ticks: {
                    color: 'rgb(80, 80, 80)',
                    font: {
                        weight: 600,
                        size: '11px'
                    },
                    maxTicksLimit: 31
                },
                grid: {
                    drawBorder: true,
                    color: 'rgb(240, 240, 240)'
                },
                border: {
                    color: 'rgb(128, 128, 128)'
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    family: "Manrope",
                    weight: 400
                }
            },
            legend: {
                position: "bottom"
            },
            axis: {
                font: {
                    size: "8px",
                    color: 'rgb(0, 0, 255)'
                }
            },
            tooltip: {
                backgroundColor: 'rgb(128, 128, 128)',
                callbacks: {
                    title: (context) => {
                        return labels[context[0].dataIndex];
                    }
                }
            }
        }
    };

    return (
        <div style={{width: "980px"}}>
            <Line data={data} options={options} height={"72px"}/>
        </div>
    );
}


const basePointClass = `
  .reportBasePoint {
  }
`;


const COST_CACHE_KEY_NAME = "costCache";


function composeTotal(generalData, vehicles, routes, costCache) {
    const ids = vehicles.map(item => item.id);
    const result = {
        peoples: 0,
        income: 0
    };

    for (const v of Object.keys(generalData)) {
        const key = Number(v);

        if (!ids.includes(key)) {
            continue;
        }

        const item = vehicles.find(v => (v.id === key));
        const itemRoute = routes?.routes.find(v => (v.id === item?.route)) || null;
        const peoples = generalData[key].reduce((acc, v) => {
            return acc + v
        }, 0);

        result.peoples += peoples;
        if (itemRoute) {
            result.income += peoples * itemRoute.cost;
        } else if (costCache && costCache[key]) {
            result.income += peoples * costCache[key];
        }
    }
    // console.log(`result = ${JSON.stringify(result)}`);
    return result;
}


const ReportDailyTable = ({fromDate, toDate, dataset, onVehicleClick}) => {
    const dates = Array.from(
        {length: differenceInDays(toDate, fromDate)},
        (v, k) => {
            // if (dataset[k] === undefined) {
            //     // Это быстрый простой фикс проблемы нерабочих отчётов с полуночи до 3 часов ночи.
            //     return {
            //         date: addDays(fromDate, k),
            //         count: 0,
            //         income: 0
            //     };
            // }
            return {
                date: addDays(fromDate, k),
                // Ниже другой быстрый простой фикс проблемы нерабочих отчётов с полуночи до 3 часов ночи.
                count: dataset[k]?.peoples || 0,
                income: dataset[k]?.income || 0
            };
        //}).filter((item) => {
        //     return item !== false;
    });
    const isShortDate = isSameYear(fromDate, toDate);
    const itemPerColumn = 11;
    const itemPerPage = itemPerColumn * 3;
    const totalPages = calcChunkCount(dates, itemPerPage);

    const [curPage, setCurPage] = useState(0);
    const base = curPage * itemPerPage;

    const tableClickHandler = (e) => {
        e.preventDefault();
        onVehicleClick(e.target.parentNode.dataset.rowid);
    };

    const DailyColumn = ({dates}) => {
        return (
            <>
                {dates.length ?
                    <table
                        className="uk-table uk-table-striped uk-table-hover uk-table-small"
                        style={{fontSize: "13px"}}>
                        <thead>
                        <tr>
                            <th style={{width: "100px"}}>
                                <TableHead>Дата</TableHead>
                            </th>
                            <th>
                                <TableHead>Перевезено<br/>пассажиров</TableHead>
                            </th>
                            <th>
                                <TableHead>Выручка</TableHead>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dates.map((item, key) => {
                                return (
                                    <tr key={key} data-rowid={formatLongDate(item.date)}>
                                        <td style={{cursor: "pointer"}}
                                            onClick={(e) => tableClickHandler(e)}>
                                            {isShortDate ?
                                                item.date.toLocaleString('ru-RU', {
                                                    month: 'numeric',
                                                    day: 'numeric'
                                                }) :
                                                item.date.toLocaleString('ru-RU', {
                                                    year: "numeric",
                                                    month: 'numeric',
                                                    day: 'numeric'
                                                })
                                            }
                                        </td>
                                        <td>{item.count || "-"}</td>
                                        <td><Rubles value={item.income} /></td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table> :
                    <div></div>
                }
            </>
        );
    };

    return (
        <>
            <div className={"uk-grid uk-grid-collapse uk-child-width-expand"}>
                <div>
                    <DailyColumn dates={dates.slice(base, base + itemPerColumn)}/>
                </div>
                <div className={"uk-width-1-6"} style={{width: "20px"}}></div>
                <div>
                    <DailyColumn dates={dates.slice(base + itemPerColumn, base + 2 * itemPerColumn)}/>
                </div>
                <div className={"uk-width-1-6"} style={{width: "20px"}}></div>
                <div>
                    <DailyColumn dates={dates.slice(base + 2 * itemPerColumn, base + 3 * itemPerColumn)}/>
                </div>
            </div>
            {(totalPages > 1) &&
                <div className={"uk-margin-top"}>
                    <Pager curPage={curPage} setCurPage={setCurPage} totalPages={totalPages}/>
                </div>
            }
        </>
    );
}


const ReportIntradayTable = ({dataset, vehicle, day, setMode, setStoppageVehicle, setStoppageDay, setStoppageHour}) => {
    const HourlyColumn = ({base, data}) => {
        return (
            <>
                <div>
                    <table
                        className="uk-table uk-table-striped uk-table-hover uk-table-small"
                        style={{fontSize: "13px"}}
                    >
                        <thead>
                        <tr>
                            <th style={{width: "130px"}}>
                                <TableHead>Время</TableHead>
                            </th>
                            <th>
                                <TableHead>Перевезено<br/>пассажиров</TableHead>
                            </th>
                            <th>
                                <TableHead>Выручка</TableHead>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data?.map((item, key) => {
                                const hour = base + key;

                                return (
                                    <tr key={key}>
                                        {vehicle ?
                                            <td style={{cursor: "pointer"}}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setStoppageVehicle(vehicle);
                                                    setStoppageDay(day);
                                                    setStoppageHour(hour);
                                                    setMode(3);
                                                }}>
                                                {hour}:00&nbsp;&ndash;&nbsp;{hour}:59
                                            </td>
                                            :
                                            <td>
                                                {hour}:00&nbsp;&ndash;&nbsp;{hour}:59
                                            </td>
                                        }
                                        <td>{item.peoples || "-"}</td>
                                        <td><Rubles value={item.income} /></td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    return (
        <>
            <div className={"uk-grid uk-grid-collapse uk-child-width-expand"}>
                <div>
                    <HourlyColumn base={0} data={dataset?.slice(0, 8)}/>
                </div>
                <div className={"uk-width-1-6"} style={{width: "20px"}}></div>
                <div>
                    <HourlyColumn base={8} data={dataset?.slice(8, 16)}/>
                </div>
                <div className={"uk-width-1-6"} style={{width: "20px"}}></div>
                <div>
                    <HourlyColumn base={16} data={dataset?.slice(16, 24)}/>
                </div>
            </div>
        </>
    );
}


const HourlyChart = ({title, dataset}) => {
    const labels = Array.from({length: 24}, (v, k) => {
        return `${k}:00`;
    });
    const data = {
        labels,
        datasets: [
            {
                label: 'Перевезено пассажиров',
                data: dataset,
                spanGaps: false,
                borderColor: '#1e87f0',
                backgroundColor: 'rgb(255, 255, 255)'
            }
        ]
    };
    const options = {
        animation: false,
        responsive: true,
        normalized: true,
        scales: {
            y: {
                min: 0,
                // max: 2500,
                ticks: {
                    color: 'rgb(80, 80, 80)',
                    font: {
                        size: '11px'
                    }
                },
                grid: {
                    color: 'rgb(240, 240, 240)'
                },
                border: {
                    color: 'rgb(128, 128, 128)'
                }
            },
            x: {
                ticks: {
                    color: 'rgb(80, 80, 80)',
                    font: {
                        weight: 600,
                        size: '11px'
                    },
                    maxTicksLimit: 31
                },
                grid: {
                    drawBorder: true,
                    color: 'rgb(240, 240, 240)'
                },
                border: {
                    color: 'rgb(128, 128, 128)'
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    family: "Manrope",
                    weight: 400
                }
            },
            legend: {
                position: "bottom"
            },
            axis: {
                font: {
                    size: "8px",
                    color: 'rgb(0, 0, 255)'
                }
            },
            tooltip: {
                backgroundColor: 'rgb(128, 128, 128)',
                callbacks: {
                    title: (context) => {
                        return labels[context[0].dataIndex];
                    }
                }
            }
        }
    };

    return (
        <div style={{width: "980px"}}>
            <Line data={data} options={options} height={"72px"}/>
        </div>
    );
}


function filterChartData(generalData, vehicles) {
    const ids = vehicles.map(item => item.id);
    let data = {};

    for (const key of Object.keys(generalData)) {
        if (ids.includes(Number(key))) {
            data[key] = generalData[key].map(item => (item === 0) ? undefined : item);
        }
    }
    return data;
}


function saveAsDialog(blob, name) {
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
}


function parseHeaders(result) {
    const disp = result.headers['content-disposition'];
    const file = disp?.match(/filename="([^"]+)"/i)[1];
    const body = new Blob([result.data], {type: disp['content-type']});

    // console.log(`disp=${disp}`);
    // console.log(`file=${file}`);
    // console.log(`body=${body.size} byte(s)`);

    return {
        file: file,
        body: body
    };
}


const OverallReport = ({
                           availableRange,
                           routes,
                           transport,
                           costCache,
                           generalRoute,
                           setGeneralRoute,
                           generalMonthRange,
                           setGeneralMonthRange,
                           setCostCache,
                           setMode,
                           setDailyRange,
                           setDailySelection
}) => {
    const OVERALL_REPORT_CACHE_KEY_NAME = "overallReportCache";

    const navigate = useNavigate();
    const [reportLoading, setReportLoading] = useState(true);

    const generalVehicles = transport?.vehicles?.filter(item => {
        if (!generalRoute) {
            return true;
        }
        return item.route === generalRoute;
    }) || [];
    const [generalData, setGeneralData] = useState({});

    const [overall, setOverall] = useState({});

    useEffect(() => {
        // this is quick & dirty hack
        const o = composeTotal(generalData, generalVehicles, routes, costCache);

        if (o?.income !== overall?.income || o?.peoples !== overall?.peoples) {
            setOverall(o);
        }
    }, [generalData, generalVehicles, routes, costCache]);

    const getRangeHash = (range) => {
        if (!range?.fromMonth || !range?.toMonth) {
            return null;
        }
        return formatShortDate(range.fromMonth) + formatShortDate(range.toMonth);
    };

    useEffect(() => {
        const hash = getRangeHash(generalMonthRange);
        let cache = getStorageValue(OVERALL_REPORT_CACHE_KEY_NAME) || {};

        if (hash in cache) {
            setGeneralData(cache[hash]);
            setReportLoading(false);
            return;
        }

        setReportLoading(true);

        const token = getAccessToken();

        apiReportOverall(token, generalMonthRange.fromMonth, generalMonthRange.toMonth, null)
            .then((result) => {
            setGeneralData(result.data.report);
            setReportLoading(false);

            cache[hash] = result.data.report;
            setStorageValue(OVERALL_REPORT_CACHE_KEY_NAME, cache);
        })
            .catch((e) => {
            console.log(`apiReportOverall error ${e}`);
            setReportLoading(false);
            navigate('/error');
        });
    }, [generalMonthRange]);

    const chartEnabled = (((isFuture(generalMonthRange.toMonth) ? startOfToday() : generalMonthRange.toMonth) - generalMonthRange.fromMonth) > (2 * 24 * 60 * 60 * 1000 - 1));

    const isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0;
    }

    const [enableDownload, setEnableDownload] = useState(true);

    const downloadReport = (e) => {
        e.preventDefault();
        setEnableDownload(false);

        const token = getAccessToken();

        apiReportOverall(token, generalMonthRange.fromMonth, generalMonthRange.toMonth, 'excel').then((result) => {
            const { file, body } = parseHeaders(result);

            saveAsDialog(body, file);
            setEnableDownload(true);
        }).catch((e) => {
            UIkit.notification("<span style='font-size: 14px; color: #e00000'>Не удалось загрузить отчёт... Попробуйте, пожалуйста, позднее.</span>");
            setEnableDownload(true);
        });
    }

    useEffect(() => {
        document.body.style.cursor = (enableDownload ? null : "wait");
    }, [enableDownload]);

    return (
        <>
            {!reportLoading && <div>
                <div>
                    <div
                        className={"uk-grid uk-grid-collapse uk-child-width-expand uk-grid-match uk-container"}>
                        <div className={"uk-width-expand"} style={{alignItems: "center"}}>
                            <span style={{verticalAlign: "middle"}}>Статистика по перевезенным пассажирам:</span>
                        </div>
                        <div className={"uk-width-auto"}>
                            <ReportRoutePicker
                                route={generalRoute}
                                routes={routes?.routes}
                                onApply={(route) => {
                                    // console.log(`route=${route}`);
                                    setGeneralRoute(route);
                                }}
                            />
                        </div>
                        <div className={"uk-width-auto uk-margin-small-left"}>
                            <ReportMonthRangePicker
                                selectedRange={generalMonthRange}
                                availableRange={availableRange}
                                onApply={(left, right) => {
                                    // console.log(`left = ${left}, right = ${right}`);
                                    setReportLoading(true);  // this is dirty hack
                                    setGeneralMonthRange({
                                        fromMonth: left,
                                        toMonth: right
                                    });
                                }}
                            />
                        </div>
                        <div
                            className={"uk-width-auto uk-margin-small-left reportBasePoint"}
                            style={{alignItems: "center"}}
                        >
                            <button
                                className="uk-button uk-button-primary uk-button-small"
                                disabled={!enableDownload}
                                onClick={(e) => downloadReport(e)}
                            >
                                Скачать
                            </button>
                        </div>
                    </div>
                </div>
                {!isObjectEmpty(generalData) && <div>
                    <div className={"uk-margin-small"}>
                        {chartEnabled ?
                            <div style={{width: "980px", height: "235px"}}>
                                <DailyChartEx
                                    title={"Перевезено пассажиров за выбранный период"}
                                    fromDate={generalMonthRange.fromMonth}
                                    toDate={isFuture(generalMonthRange.toMonth) ? startOfToday() : generalMonthRange.toMonth}
                                    dataset={filterChartData(generalData, generalVehicles)}
                                />
                            </div>
                            :
                            <div style={{
                                width: "980px",
                                height: "235px",
                                textAlign: "center",
                                lineHeight: "235px"
                            }}>
                                <span style={{fontSize: "12px", color: "indianred"}}>
                                    <b>Слишком короткий диапазон для отображения графика. Диапазон должен быть как минимум два дня.</b>
                                </span>
                            </div>
                        }
                    </div>
                    <div className={"uk-margin-small"}>
                        <div>
                            Всего перевезено <b>{overall.peoples}</b> человек.
                            Общая расчётная выручка за период <b>{overall.income}</b>&nbsp;&#8381;.
                        </div>
                    </div>
                    <div className={"uk-margin-small-top"}>
                        <ReportVehicleTable
                            vehicles={generalVehicles}
                            routes={routes}
                            data={generalData}
                            costCache={costCache}
                            onSetPrice={(v, cost) => {
                                const updated = costCache;

                                updated[v] = cost;
                                setCostCache(updated);
                                // next line is needed, cause useEffect dependencies is not working...
                                // search for "this is quick & dirty hack" string
                                setOverall(composeTotal(generalData, generalVehicles, routes, costCache));
                                console.log(`cache=${JSON.stringify(updated)}`);
                            }}
                            onVehicleClick={(v) => {
                                console.log(`onVehicleClick = ${v}, generalMonthRange = ${JSON.stringify(generalMonthRange)}, v=${v}`);
                                setReportLoading(true);
                                setDailySelection({
                                    type: 1, id: v
                                });
                                setDailyRange({
                                    fromDate: generalMonthRange.fromMonth,
                                    toDate: isPast(generalMonthRange.toMonth) ? generalMonthRange.toMonth : startOfToday()
                                });
                                setMode(1);
                            }}
                        />
                    </div>
                    <div className={"uk-margin-top"}>
                        <div className={"uk-alert uk-alert-primary uk-margin-small-bottom"}>
                            Кликнув по наименованию ТС можно быстро перейти в ежедневный отчёт по
                            данному ТС. При этом диапазон дат, выбранный в этом отчёте будет
                            применен и к ежедневному отчёту.
                        </div>
                        <div className={"uk-alert uk-alert-danger uk-margin-small-top"}>
                            Для тех ТС, которые не назначены на маршрут, можно ввести стоимость
                            проезда вручную.
                            Введенная вручную стоимость проезда сохранится только в текущем сеансе
                            работы. Если Вы не хотите каждый раз при построении отчёта вводить
                            стоимость проезда, то Вам нужен функционал <b>Маршруты</b>, с которым
                            можно работать в одноименном разделе меню слева. С ним Вы сможете один
                            раз задать стоимость и затем она будет автоматически применяться при
                            построении отчётов.
                        </div>
                    </div>
                </div>}
                {isObjectEmpty(generalData) &&
                    <div style={{
                        width: "980px",
                        height: "235px",
                        textAlign: "center",
                        lineHeight: "235px"
                    }}>
                        <span style={{fontSize: "12px", color: "indianred"}}>
                            <b>Нет данных для выбранной даты и маршрута и/или ТС.</b>
                        </span>
                    </div>
                }
            </div>}
            {reportLoading && <ReportLoadingStub/>}
        </>
    );
}


const DailyReport = ({
                         availableRange,
                         routes,
                         transport,
                         costCache,
                         dailyRange,
                         setDailyRange,
                         dailySelection,
                         setDailySelection,
                         setMode,
                         setIntradayDay,
                         setIntradaySelection
}) => {
    const DAILY_REPORT_CACHE_KEY_NAME = "dailyReportCache";

    const navigate = useNavigate();
    const [reportLoading, setReportLoading] = useState(true);

    const [dailyData, setDailyData] = useState({});

    const getReportHash = (range, selection) => {
        // console.log(`in = ${JSON.stringify(range)}, ${JSON.stringify(selection)}`);
        if (!range || !selection) {
            return null;
        }
        return `${selection.type}.${selection.id || -1}.${formatLongDate(range.fromDate)}.${formatLongDate(range.toDate)}`;
    }

    useEffect(() => {
        const hash = getReportHash(dailyRange, dailySelection);
        let cache = getStorageValue(DAILY_REPORT_CACHE_KEY_NAME) || {};

        if (hash in cache) {
            setDailyData(cache[hash]);
            setReportLoading(false);
            return;
        }

        setReportLoading(true);

        const token = getAccessToken();
        const selType = Number(dailySelection.type);

        apiReportDaily(
            token,
            (selType === 0) ? dailySelection.id : null,
            (selType === 1) ? dailySelection.id : null,
            dailyRange.fromDate,
            dailyRange.toDate
        ).then((result) => {
            setDailyData(result.data.report);
            setReportLoading(false);

            cache[hash] = result.data.report;
            setStorageValue(DAILY_REPORT_CACHE_KEY_NAME, cache);
        }).catch((e) => {
            console.log(`apiReportDaily error ${e}`);
            setReportLoading(false);
            navigate('/error');
        });
    }, [
        dailyRange,
        dailySelection
    ]);

    const composeData = (data) => {
        if (!data) {
            return 0;
        }

        let result = null;

        // console.log(`compose=${JSON.stringify(data)}`);
        for (const key of Object.keys(data)) {
            const item = transport?.vehicles?.find(v => (v.id === Number(key)));
            const itemRoute = routes?.routes.find(v => (v.id === Number(item.route))) || null;
            const cost = itemRoute ? itemRoute.cost : (costCache[key] || 0);

            if (!result) {
                result = data[key].map(v => {
                    return {
                        peoples: v,
                        income: v * cost
                    }
                });
                continue;
            }
            result = data[key].map((v, j) => {
                return {
                    peoples: (result[j]?.peoples || 0) + v,
                    income: (result[j]?.income || 0) + v * cost
                };
            });
        }
        // console.log(`result=${JSON.stringify(result)}`);
        return result;
    };

    const dataset = composeData(dailyData);
    const peoples = dataset?.reduce((acc, v) => {
        return acc + v.peoples;
    }, 0) || 0;
    const income = dataset?.reduce((acc, v) => {
        return acc + v.income;
    }, 0) || 0;

    const [enableDownload, setEnableDownload] = useState(true);

    const downloadReport = (e) => {
        e.preventDefault();
        setEnableDownload(false);

        const token = getAccessToken();
        const selType = Number(dailySelection.type);

        apiReportDaily(
            token,
            (selType === 0) ? dailySelection.id : null,
            (selType === 1) ? dailySelection.id : null,
            dailyRange.fromDate,
            dailyRange.toDate,
            'excel'
        ).then((result) => {
            const { file, body } = parseHeaders(result);

            saveAsDialog(body, file);
            setEnableDownload(true);
        }).catch((e) => {
            UIkit.notification("<span style='font-size: 14px; color: #e00000'>Не удалось загрузить отчёт... Попробуйте, пожалуйста, позднее.</span>");
            setEnableDownload(true);
        });
    }

    useEffect(() => {
        document.body.style.cursor = (enableDownload ? null : "wait");
    }, [enableDownload]);

    return (
        <>
            {!reportLoading && <div>
                <div>
                    <div
                        className={"uk-grid uk-grid-collapse uk-child-width-expand uk-grid-match uk-container"}>
                        <div className={"uk-width-expand"} style={{alignItems: "center"}}>
                            <span>Статистика по перевезенным пассажирам:</span>
                        </div>
                        <div className={"uk-width-auto"}>
                            <ReportRouteOrVehiclePicker
                                selected={dailySelection}
                                routes={routes?.routes}
                                vehicles={transport?.vehicles}
                                onApply={(selection) => {
                                    // console.log(`ReportRouteOrVehiclePicker : apply=${JSON.stringify(selection)}`);
                                    setDailySelection(selection);
                                    setReportLoading(true);
                                }}/>
                        </div>
                        <div className={"uk-width-auto uk-margin-small-left"}>
                            <ReportDateRangePicker
                                selectedRange={dailyRange}
                                availableRange={availableRange}
                                onApply={(range) => {
                                    // console.log(`left = ${range.fromDate}, right = ${range.toDate}`);
                                    setDailyRange(range);
                                    setReportLoading(true);
                                }}
                            />
                        </div>
                        <div
                            className={"uk-width-auto reportBasePoint"}
                            style={{alignItems: "center"}}
                        >
                            <div className={"uk-margin-small-left"}>
                                <button
                                    className="uk-button uk-button-primary uk-button-small"
                                    disabled={!enableDownload}
                                    onClick={(e) => downloadReport(e)}
                                >
                                    Скачать
                                </button>
                            </div>
                        </div>
                    </div>
                    {dataset && <div>
                        <div className={"uk-margin-small"}>
                            {!isSameDay(dailyRange.fromDate, subMinutes(dailyRange.toDate, 1)) ?
                                <div style={{width: "980px", height: "235px"}}>
                                    <DailyChartEx
                                        title={"Перевезено пассажиров за выбранный период"}
                                        fromDate={dailyRange.fromDate}
                                        toDate={dailyRange.toDate}
                                        dataset={filterChartData(dailyData, transport?.vehicles)}
                                    />
                                </div>
                                :
                                <div style={{
                                    width: "980px",
                                    height: "235px",
                                    textAlign: "center",
                                    lineHeight: "235px"
                                }}>
                                    <span style={{fontSize: "12px", color: "indianred"}}>
                                        <b>Слишком короткий диапазон для отображения графика. Диапазон должен быть как минимум два дня.</b>
                                    </span>
                                </div>
                            }
                        </div>
                        Всего перевезено: <b>{peoples}</b> человек.
                        Общая расчётная выручка за период: <b><Rubles value={income}/></b>.
                    </div>}
                    {!dataset &&
                        <div style={{
                            width: "980px",
                            height: "235px",
                            textAlign: "center",
                            lineHeight: "235px"
                        }}>
                            <span style={{fontSize: "12px", color: "indianred"}}>
                                <b>Нет данных для выбранной даты и маршрута и/или ТС.</b>
                            </span>
                        </div>
                    }
                </div>
                {dataset && <div>
                    <div className={"uk-margin-small-bottom"}>
                        <ReportDailyTable
                            fromDate={dailyRange.fromDate}
                            toDate={dailyRange.toDate}
                            dataset={dataset}
                            onVehicleClick={(v) => {
                                const day = new Date(
                                    +Number(v.substring(0, 4)),
                                    +Number(v.substring(4, 6)) - 1,
                                    +Number(v.substring(6))
                                );
                                setMode(2);
                                setIntradayDay(day);
                                setIntradaySelection(dailySelection);
                            }}
                        />
                    </div>
                    <div className={"uk-margin-top"}>
                        <div className={"uk-alert uk-alert-primary"}>
                            Стоимость проезда для ТС без маршрута можно вручную задать в сводном
                            отчёте.
                        </div>
                    </div>
                </div>}
            </div>}
            {reportLoading && <ReportLoadingStub/>}
        </>
    );
}


const IntradayReport = ({
                            availableRange,
                            routes,
                            transport,
                            costCache,
                            intradayDay,
                            setIntradayDay,
                            intradaySelection,
                            setIntradaySelection,
                            setMode,
                            setStoppageVehicle,
                            setStoppageDay,
                            setStoppageHour
}) => {
    const INTRADAY_REPORT_CACHE_KEY_NAME = "intradayReportCache";

    const navigate = useNavigate();
    const [reportLoading, setReportLoading] = useState(true);

    const [dailyData, setDailyData] = useState({});

    const getReportHash = (date, selection) => {
        return `${selection.type}.${selection.id || -1}.${formatLongDate(date)}`;
    };

    useEffect(() => {
        const hash = getReportHash(intradayDay, intradaySelection);
        let cache = getStorageValue(INTRADAY_REPORT_CACHE_KEY_NAME) || {};

        if (hash in cache) {
            setDailyData(cache[hash]);
            setReportLoading(false);
            return;
        }

        setReportLoading(true);

        const token = getAccessToken();
        const selType = Number(intradaySelection.type);

        apiReportIntraday(
            token,
            (selType === 0) ? intradaySelection.id : null,
            (selType === 1) ? intradaySelection.id : null,
            intradayDay
        ).then((result) => {
            setDailyData(result.data.report);
            setReportLoading(false);
            console.log(`apiReportIntraday = ${JSON.stringify(result.data.report)}`);

            cache[hash] = result.data.report;
            setStorageValue(INTRADAY_REPORT_CACHE_KEY_NAME, cache);
        }).catch((e) => {
            console.log(`apiReportIntraday error ${e}`);
            setReportLoading(false);
            navigate('/error');
        });
    }, [intradayDay, intradaySelection]);

    const composeData = (data) => {
        if (!data) {
            return 0;
        }

        let result = null;

        for (const key of Object.keys(data)) {
            const item = transport?.vehicles?.find(v => (v.id === Number(key)));
            const itemRoute = routes?.routes.find(v => (v.id === Number(item.route))) || null;
            const cost = itemRoute ? itemRoute.cost : (costCache[key] || 0);

            if (!result) {
                result = data[key].map(v => {
                    return {
                        peoples: v,
                        income: v * cost
                    }
                });
                continue;
            }
            result = data[key].map((v, j) => {
                return {
                    peoples: result[j].peoples + v,
                    income: result[j].income + v * cost
                };
            });
        }
        console.log(`result=${JSON.stringify(result)}`);
        return result;
    };

    const dataset = composeData(dailyData);
    const peoples = dataset?.reduce((acc, v) => {
        return acc + v.peoples;
    }, 0) || 0;
    const income = dataset?.reduce((acc, v) => {
        return acc + v.income;
    }, 0) || 0;

    const [enableDownload, setEnableDownload] = useState(true);

    const downloadReport = (e) => {
        e.preventDefault();
        setEnableDownload(false);

        const token = getAccessToken();
        const selType = Number(intradaySelection.type);

        apiReportIntraday(
            token,
            (selType === 0) ? intradaySelection.id : null,
            (selType === 1) ? intradaySelection.id : null,
            intradayDay,
            'excel'
        ).then((result) => {
            const { file, body } = parseHeaders(result);

            saveAsDialog(body, file);
            setEnableDownload(true);
        }).catch((e) => {
            UIkit.notification("<span style='font-size: 14px; color: #e00000'>Не удалось загрузить отчёт... Попробуйте, пожалуйста, позднее.</span>");
            setEnableDownload(true);
        });
    }

    useEffect(() => {
        document.body.style.cursor = (enableDownload ? null : "wait");
    }, [enableDownload]);

    return (
        <>
            {!reportLoading && <div>
                <div>
                    <div
                        className={"uk-grid uk-grid-collapse uk-child-width-expand uk-grid-match uk-container"}>
                        <div className={"uk-width-expand"} style={{alignItems: "center"}}>
                            <span style={{verticalAlign: "middle"}}>Статистика по перевезенным пассажирам:</span>
                        </div>
                        <div className={"uk-width-auto"}>
                            <ReportRouteOrVehiclePicker
                                selected={intradaySelection}
                                routes={routes?.routes}
                                vehicles={transport?.vehicles}
                                onApply={(selection) => {
                                    console.log(`apply=${JSON.stringify(selection)}`);
                                    setIntradaySelection(selection);
                                    setReportLoading(true);
                                }}
                            />
                        </div>
                        <div className={"uk-width-auto uk-margin-small-left"}>
                            <ReportDayPicker
                                day={intradayDay}
                                onApply={(day) => {
                                    console.log(`day=${day}`);
                                    setIntradayDay(day);
                                    setReportLoading(true);
                                }}
                            />
                        </div>
                        <div
                            className={"uk-width-auto uk-margin-small-left reportBasePoint"}
                            style={{alignItems: "center"}}
                        >
                            <button
                                className="uk-button uk-button-primary uk-button-small"
                                disabled={!enableDownload}
                                onClick={(e) => downloadReport(e)}
                            >
                                Скачать
                            </button>
                        </div>
                    </div>
                    {dataset && <div>
                        <div className={"uk-margin-small"} style={{width: "980px", height: "235px"}}>
                            <HourlyChart
                                title={"Перевезено пассажиров за сутки"}
                                dataset={dataset.map((v) => v.peoples || null)}
                            />
                        </div>
                        Всего перевезено: <b>{peoples}</b> человек.
                        Общая расчётная выручка за период: <b><Rubles value={income}/></b>.
                    </div>}
                    {!dataset &&
                        <div style={{
                            width: "980px",
                            height: "235px",
                            textAlign: "center",
                            lineHeight: "235px"
                        }}>
                            <span style={{fontSize: "12px", color: "indianred"}}>
                                <b>Нет данных для выбранной даты и маршрута и/или ТС.</b>
                            </span>
                        </div>
                    }
                </div>
                {dataset && <div>
                    <div className={"uk-margin-small-top"}>
                        <ReportIntradayTable
                            dataset={dataset}
                            vehicle={(intradaySelection.type === 1) ? intradaySelection.id : null}
                            day={intradayDay}
                            setMode={setMode}
                            setStoppageVehicle={setStoppageVehicle}
                            setStoppageDay={setStoppageDay}
                            setStoppageHour={setStoppageHour}
                        />
                    </div>
                    <div>
                        <div className={"uk-alert uk-alert-primary"}>
                            Стоимость проезда для ТС без маршрута можно вручную задать в сводном отчёте.
                        </div>
                    </div>
                </div>}
            </div>}
            {reportLoading && <ReportLoadingStub/>}
        </>
    );
}


const HlsPlayer = ({clip, poster, playState, setPlayState, token}) => {
    const w = 704;
    const h = 576;

    const playerRef = React.useRef(null);
    const videoJsOptions = {
        autoplay: true,
        controls: true,
        fluid: true,
        muted: true,
        responsive: true,
        html5: {
            vhs: {
                withCredentials: true,
                overrideNative: true
            }
        }
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('waiting', () => {
            console.log('player is waiting');
        });
        player.on('dispose', () => {
            console.log('player will dispose');
        });
        player.on('play', () => {
            setPlayState(true);
            console.log(`PLAY event raised`);
        });
        player.on('pause', () => {
            setPlayState(false);
            console.log(`PAUSE event raised`);
        });
        // player.on('xhr-hooks-ready', () => {
        //     const playerXhrRequestHook = (options) => {
        //         options.beforeSend = (xhr) => {
        //             xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        //         };
        //         return options;
        //     };
        //     console.log(`xhr-hooks-ready called`);
        //     player.tech().vhs.xhr.onRequest(playerXhrRequestHook);
        // });
        player.src({
            src: clip,
            //type: 'application/x-mpegURL',
            type: 'video/mp4'
        });
        console.log(`HlsPlayer setup for URL '${clip}'`);
    };

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.src({
                src: clip,
                //type: 'application/x-mpegURL',
                type: 'video/mp4'
            });
        }
    }, [playerRef.current, clip]);

    useEffect(() => {
        console.log(`useEffect called`);
        if (playerRef.current && !playState) {
            playerRef.current.pause();
            console.log(`player is on pause`);
        }
    }, [playState]);

    return (
        <>
            <div style={{display: "inline-block"}}>
                <div style={{padding: "4px", backgroundColor: "black"}}>
                    <VideoJS
                        options={videoJsOptions}
                        onReady={handlePlayerReady}
                        width={w}
                        height={h}
                    />
                </div>
            </div>
        </>
    );
}


const Clip = ({
                  ts, thumb, title,
                  peoples,
                  duration,
                  tag,
                  onClipClick
}) => {
    const NOVIDEO_IMG = 'novideo.png';
    const LOADING_IMG = 'loading.png';

    const formatTime = (ts) => {
        const h = ts.getHours();
        const m = ts.getMinutes();

        return ((h < 10) ? `0${h}` : `${h}`) + ':' + ((m < 10) ? `0${m}` : `${m}`);
    };

    const formatDuration = (secs) => {
        const m = Math.floor(secs / 60);
        const s = Math.floor(secs % 60);

        return `${m}` + ':' + ((s < 10) ? `0${s}` : `${s}`);
    };

    const thumbRef = useRef(null);

    useLayoutEffect(() => {
        if (!thumb || !thumbRef.current) {
            return;
        }

        // loading thumbnail here
        const xhr = new XMLHttpRequest();
        let obj = null;

        xhr.open('GET', thumb, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + getAccessToken());
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
            if (xhr.status !== 200) {
                console.log(`something wrong = ${xhr.status}`);
                thumbRef.current.src = NOVIDEO_IMG;
                return;
            }

            const blob = xhr.response;

            obj = URL.createObjectURL(blob);
            if (thumbRef.current) {
                thumbRef.current.src = obj;
                // console.log(`onload(): ${thumb} -> ${blob.size} (${blob.type})`);
            }
        };
        xhr.onerror = function() {
            console.log(`error happens = ${xhr.status}`);
            if (thumbRef.current) {
                thumbRef.current.src = NOVIDEO_IMG;
            }
        };
        xhr.send();

        return () => {
            // clean-up here
            if (obj) {
                URL.revokeObjectURL(obj);
            }
            if (thumbRef.current) {
                thumbRef.current.srv = NOVIDEO_IMG;
            }
        }
    }, [thumb, thumbRef]);

    return (
        <>
            <div>
                <div
                    className={"uk-card uk-card-default uk-card-small uk-card-body"}>
                    <div style={{fontSize: "16px"}}>
                        <b><span style={{color: "black"}}>{formatTime(ts)}</span></b>
                    </div>
                    <div className={"uk-text-truncate"}>
                        <b><span>{title}</span></b>
                    </div>
                    <div className={"uk-margin-small-top uk-margin-small-bottom"}>
                        <img style={{height: "155px", objectFit: "contain", cursor: "pointer"}}
                             ref={thumbRef}
                             src={LOADING_IMG}
                             onClick={(e) => onClipClick(e, tag)}
                        />
                    </div>
                    <div>
                        Вошло: <b>{peoples.in}</b>, вышло: <b>{peoples.out}</b><br/>
                        Время стоянки: <b>{formatDuration(duration)}</b>
                    </div>
                </div>
            </div>
        </>
    );
};


const StoppageReport = ({
                            transport,
                            vehicle,
                            setVehicle,
                            day,
                            setDay,
                            hour,
                            setHour
}) => {
    const STOPPAGE_REPORT_CACHE_KEY_NAME = "stoppageReportCache";

    const navigate = useNavigate();
    const [reportLoading, setReportLoading] = useState(true);

    const onClipClick = (e, tag) => {
        e.preventDefault();
        console.log(`tag=${tag}`);
        setClip(library[tag]);
        UIkit.modal(uiModalRef.current).show();
    };

    const [library, setLibrary] = useState([]);
    const [clip, setClip] = useState(library?.at(0) || null);
    const uiModalRef = useRef(null);
    const [play, setPlay] = useState(false);

    const onBeforeHide = () => {
        console.log('onBeforeHide');
        setPlay(false);
    };

    useEffect(() => {
        UIkit.util.on(uiModalRef.current, 'beforehide', onBeforeHide);
        return () => {
            UIkit.util.off(uiModalRef.current, 'beforehide', onBeforeHide);
        }
    });

    const [stoppageData, setStoppageData] = useState(null);

    const getReportHash = (vehicle, day, hour) => {
        return `${vehicle}.${formatLongDate(day)}.${hour}`;
    };

    useEffect(() => {
        if (!vehicle) {
            setReportLoading(false);
            return;
        }

        const hash = getReportHash(vehicle, day, hour);
        let cache = getStorageValue(STOPPAGE_REPORT_CACHE_KEY_NAME) || {};

        if (hash in cache) {
            setStoppageData(cache[hash]);
            setReportLoading(false);
            return;
        }

        setReportLoading(true);

        const token = getAccessToken();

        apiReportStoppage(
            token,
            vehicle,
            day,
            hour
        ).then((result) => {
            setStoppageData(result.data.report);
            setReportLoading(false);
            console.log(`apiReportStoppage = ${JSON.stringify(result.data.report)}`);

            cache[hash] = result.data.report;
            setStorageValue(STOPPAGE_REPORT_CACHE_KEY_NAME, cache);
        }).catch((e) => {
            console.log(`apiReportStoppage error ${e}`);
            setReportLoading(false);
            navigate('/error');
        });
    }, [vehicle, day, hour]);

    useEffect(() => {
        setLibrary(stoppageData?.stops?.map(v => {
            const t = new Date(day);

            t.setHours(Number(v.ts / 60));
            t.setMinutes(Number(v.ts % 60));
            v.timestamp = t;
            delete v.ts;
            return v;
        }) || null);
    }, [stoppageData]);

    return (
        <>
            {!reportLoading && <div>
                <div className={"uk-grid uk-grid-collapse uk-child-width-expand uk-grid-match uk-container"}>
                    <div className={"uk-width-expand"} style={{alignItems: "center"}}>
                        <span style={{verticalAlign: "middle"}}>
                            Видеоархив по остановкам (<b>{library?.length || 0}</b> найдено):
                        </span>
                    </div>
                    <div className={"uk-width-auto"}>
                        <ReportVehiclePicker
                            vehicle={vehicle}
                            vehicles={transport?.vehicles}
                            onApply={(item) => {
                                console.log(`vehicle=${item}`);
                                setVehicle(item);
                                setReportLoading(true);
                            }}
                        />
                    </div>
                    <div className={"uk-width-auto uk-margin-small-left"}>
                        <ReportDayPicker
                            day={day}
                            onApply={(day) => {
                                console.log(`day=${day}`);
                                setDay(day);
                                setReportLoading(true);
                            }}
                        />
                    </div>
                    <div className={"uk-width-auto uk-margin-small-left reportBasePoint"}>
                        <ReportHourPicker
                            hour={hour}
                            onApply={(hour) => {
                                console.log(`hour=${hour}`);
                                setHour(hour);
                                setReportLoading(true);
                            }}
                        />
                    </div>
                </div>
                <div className={"uk-margin-top"}>
                    <div
                        className={"uk-grid-small uk-child-width-1-4 uk-grid-match"} data-uk-grid>
                        {library?.map((item, key) => {
                            return (
                                <Clip
                                    key={key} tag={key}
                                    ts={item.timestamp} title={item.title} peoples={item.peoples}
                                    duration={item.duration}
                                    thumb={item.thumb}
                                    onClipClick={onClipClick}
                                />);
                        })}
                    </div>
                </div>
                <div id="modal-media-video" className="uk-flex-top" data-uk-modal ref={uiModalRef}>
                    <div className="uk-modal-dialog uk-width-auto uk-margin-auto-vertical">
                        <button className="uk-modal-close-outside" type="button" data-uk-close></button>
                        {clip && <HlsPlayer
                            token={getAccessToken()}
                            clip={clip.clip} poster={clip.thumb}
                            playState={play} setPlayState={setPlay}
                        />}
                    </div>
                </div>
            </div>}
            {reportLoading && <ReportLoadingStub />}
        </>
    );
};


export default function Reports() {
    const MODE_KEY_NAME = "reportMode";
    const OVERALL_ROUTE_KEY_NAME = "overallRoute";
    const OVERALL_RANGE_KEY_NAME = "overallRange";
    const DAILY_RANGE_KEY_NAME = "dailyRange";
    const DAILY_SELECTION_KEY_NAME = "dailySelection";
    const INTRADAY_DAY_KEY_NAME = "intradayDay";
    const INTRADAY_SELECTION_KEY_NAME = "intradaySelection";
    const STOPPAGE_VEHICLE_KEY_NAME = "stoppageVehicle";
    const STOPPAGE_DAY_KEY_NAME = "stoppageDay";
    const STOPPAGE_HOUR_KEY_NAME = "stoppageHour";

    const navigate = useNavigate();
    const isLoading = useRef(false);
    const [globalLoading, setGlobalLoading] = useState(true);
    const [mode, setMode] = useState(getStorageValue(MODE_KEY_NAME) || 0);
    const profile = getStorageValue(PROFILE_KEY_NAME);
    let transport = getStorageValue(TRANSPORT_KEY_NAME);
    let routes = getStorageValue(ROUTES_KEY_NAME);

    useEffect(() => {
        // console.log(`Reports.useEffect(): transport = ${JSON.stringify(transport)}}, routes = ${JSON.stringify(routes)}`);
        if (transport && routes) {
            setGlobalLoading(false);
            return;
        }
        if (!isLoading.current) {
            isLoading.current = true;

            const accessToken = getAccessToken();

            console.log(`Reports.useEffect(): token = ${accessToken}`);
            apiTransport(accessToken).then((result) => {
                const response = result.data;

                // console.log(`Reports.useEffect(): result [1] = ${JSON.stringify(response)}`);
                setStorageValue(TRANSPORT_KEY_NAME, response);
                if (!routes) {
                    apiRoutes(accessToken).then((result) => {
                        const response = result.data;

                        // console.log(`Reports.useEffect(): result [2] = ${JSON.stringify(response)}`);
                        setStorageValue(ROUTES_KEY_NAME, response);
                        setGlobalLoading(false);
                    }).catch((e) => {
                        console.log(`Reports.useEffect(): [2] e = ${e}`);
                        navigate('/error');
                    });
                } else {
                    setGlobalLoading(false);
                }
            }).catch((e) => {
                console.log(`Reports.useEffect(): [1] e = ${e}`);
                navigate('/error');
            });
        }
    }, []);

    const reportTypes = ['Сводный отчёт', 'Ежедневный отчёт', 'Суточный отчёт', 'Отчёт по остановкам'];

    // global stuff
    const now = new Date();
    const availableRange = {
        fromDate: new Date(2023, 0, 1),
        toDate: startOfDay(now)
    };

    const [costCache, setCostCache] = useState(getStorageValue(COST_CACHE_KEY_NAME) ?? {});

    // general stuff
    const [generalRoute, setGeneralRoute] = useState(getStorageValue(OVERALL_ROUTE_KEY_NAME));
    const [generalMonthRange, setGeneralMonthRange] = useState(getStorageValue(OVERALL_RANGE_KEY_NAME) ?? {
        fromMonth: startOfMonth(now),
        toMonth: addMonths(startOfMonth(now), 1)
    });

    // daily report
    const [dailyRange, setDailyRange] = useState(getStorageValue(DAILY_RANGE_KEY_NAME) ?? {
        fromDate: subDays(availableRange.toDate, 7),
        toDate: availableRange.toDate
    });
    const [dailySelection, setDailySelection] = useState(getStorageValue(DAILY_SELECTION_KEY_NAME) ?? {type: 0, id: null});

    // intraday report
    const [intradayDay, setIntradayDay] = useState(getStorageValue(INTRADAY_DAY_KEY_NAME) ?? startOfYesterday(now));
    const [intradaySelection, setIntradaySelection] = useState(getStorageValue(INTRADAY_SELECTION_KEY_NAME) ?? {type: 0, id: null});

    // stoppage report stuff
    const [stoppageVehicle, setStoppageVehicle] = useState(getStorageValue(STOPPAGE_VEHICLE_KEY_NAME) ?? null);
    const [stoppageDay, setStoppageDay] = useState(getStorageValue(STOPPAGE_DAY_KEY_NAME) ?? startOfYesterday(now));
    const [stoppageHour, setStoppageHour] = useState(getStorageValue(STOPPAGE_HOUR_KEY_NAME) ?? 8);

    useEffect(() => {
        // first initialization if needed
        if (!stoppageVehicle) {
            setStoppageVehicle(transport?.vehicles?.at(0)?.id || null);
        }
    }, [transport]);

    // save all state to local storage
    useEffect(() => {
        setStorageValue(COST_CACHE_KEY_NAME, costCache);
        setStorageValue(MODE_KEY_NAME, mode);
        setStorageValue(OVERALL_ROUTE_KEY_NAME, generalRoute);
        setStorageValue(OVERALL_RANGE_KEY_NAME, generalMonthRange);
        setStorageValue(DAILY_RANGE_KEY_NAME, dailyRange);
        setStorageValue(DAILY_SELECTION_KEY_NAME, dailySelection);
        setStorageValue(INTRADAY_DAY_KEY_NAME, intradayDay);
        setStorageValue(INTRADAY_SELECTION_KEY_NAME, intradaySelection);
        setStorageValue(STOPPAGE_VEHICLE_KEY_NAME, stoppageVehicle);
        setStorageValue(STOPPAGE_DAY_KEY_NAME, stoppageDay);
        setStorageValue(STOPPAGE_HOUR_KEY_NAME, stoppageHour);
    }, [
        costCache,
        mode,
        generalRoute, generalMonthRange,
        dailyRange, dailySelection,
        intradayDay, intradaySelection,
        stoppageVehicle, stoppageDay, stoppageHour
    ]);

    // console.log(`available = ${JSON.stringify(availableRange)}`);
    // console.log(`timezone = ${clientTimeZone}`);
    // console.log(`dailyRange = ${JSON.stringify(dailyRange)}`);
    // console.log(`overall = ${JSON.stringify(overall)}`);
    return (<>
        <style>{calendarStyling}</style>
        <style>{basePointClass}</style>
        <div style={{display: "block"}}>
            {!globalLoading &&
                <div>
                    <div style={{display: "block"}}>
                        <div style={{width: "980px"}}>
                            <div className={"uk-margin-bottom"}>
                                <TabMenu items={reportTypes} mode={mode} setMode={setMode}/>
                            </div>
                            <div id="body" style={{display: "block", width: "100%"}}>
                                {(mode === 0) &&
                                    <OverallReport
                                        availableRange={availableRange}
                                        routes={routes}
                                        transport={transport}
                                        costCache={costCache}
                                        setCostCache={setCostCache}
                                        generalRoute={generalRoute}
                                        setGeneralRoute={setGeneralRoute}
                                        generalMonthRange={generalMonthRange}
                                        setGeneralMonthRange={setGeneralMonthRange}
                                        setMode={setMode}
                                        setDailyRange={setDailyRange}
                                        setDailySelection={setDailySelection}
                                    />
                                }
                                {(mode === 1) &&
                                    <DailyReport
                                        availableRange={availableRange}
                                        routes={routes}
                                        transport={transport}
                                        costCache={costCache}
                                        dailyRange={dailyRange}
                                        setDailyRange={setDailyRange}
                                        dailySelection={dailySelection}
                                        setDailySelection={setDailySelection}
                                        setMode={setMode}
                                        setIntradayDay={setIntradayDay}
                                        setIntradaySelection={setIntradaySelection}
                                    />
                                }
                                {(mode === 2) &&
                                    <IntradayReport
                                        availableRange={availableRange}
                                        routes={routes}
                                        transport={transport}
                                        costCache={costCache}
                                        intradayDay={intradayDay}
                                        setIntradayDay={setIntradayDay}
                                        intradaySelection={intradaySelection}
                                        setIntradaySelection={setIntradaySelection}
                                        setMode={setMode}
                                        setStoppageVehicle={setStoppageVehicle}
                                        setStoppageDay={setStoppageDay}
                                        setStoppageHour={setStoppageHour}
                                    />
                                }
                                {(mode === 3) &&
                                    <StoppageReport
                                        transport={transport}
                                        vehicle={stoppageVehicle}
                                        setVehicle={setStoppageVehicle}
                                        day={stoppageDay}
                                        setDay={setStoppageDay}
                                        hour={stoppageHour}
                                        setHour={setStoppageHour}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {globalLoading && <Spinner/>}
        </div>
    </>);
}


/// eof ///