import {styled} from "styled-components";
import {Version} from "./Misc";


const CopyrightStyling = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 11.75px;
  color: black;
  margin: 0 0 12px 0;
`;


function MultilineCopyright() {
    return (<>
        <CopyrightStyling>
            <div>
                Все права защищены<br/>
                ООО &laquo;
                <span style={{fontWeight: "600"}}>
                    <a href={"https://www.smartneurotech.ru"} style={{color: "black"}} target="_blank"
                       rel="noopener noreferrer">СмартНейроТех</a>
                </span>&raquo;<br/>
                &#169; 2022&#8211;2024<br/>
            </div>
            <div style={{padding: "4px 0 4px 0"}}>
                &laquo;Облачный Сервис<br/>Аналитики Пассажирского Трафика &laquo;
                <span style={{fontWeight: "600"}}>ОСА-ПТ</span>
                &raquo;&raquo;
            </div>
            {(process.env.NODE_ENV === 'development') && <Version/>}
        </CopyrightStyling>
    </>);
}


function SinglelineCopyright() {
    return (<>
        <CopyrightStyling>
            <div>
                Все права защищены ООО&nbsp;&laquo;<span style={{fontWeight: "600"}}>
                <a href={"https://www.smartneurotech.ru"} style={{color: "black"}} target="_blank" rel="noopener noreferrer">
                    СмартНейроТех
                </a>
                </span>&raquo; &#169; 2022&#8211;2024
            </div>
            <div>
                &laquo;Облачный Сервис Аналитики Пассажирского Трафика &laquo;
                    <span style={{fontWeight: "600"}}>ОСА-ПТ</span>
                &raquo;&raquo;
            </div>
        </CopyrightStyling>
    </>);
}


export {MultilineCopyright, SinglelineCopyright};


// eof
