import React, {useRef, useState} from "react";
import {getStorageValue} from "./storage";
import {PROFILE_KEY_NAME} from "./Profile";
import UIkit from "uikit";


export default function Settings() {
    const profile = getStorageValue(PROFILE_KEY_NAME);
    const [formState, setFormState] = useState({
        login: profile?.user?.login,
        name: profile?.user?.name,
        jobTitle: profile?.user?.jt,
        phone: profile?.user?.phone,
        email: profile?.user?.email
    });
    const uiChangePassDialogRef = useRef(null);
    const uiSaveDialogRef = useRef(null);

    function saveHandler(e) {
        e.preventDefault();

        const element = uiSaveDialogRef.current;

        // TODO:

        UIkit.modal(element).show();
    }

    function changePassHandler(e) {
        e.preventDefault();

        const element = uiChangePassDialogRef.current;

        // TODO:

        UIkit.modal(element).show();
    }

    return (<>
        <div style={{display: "block", width: "670px"}}>
            <h5 className={"uk-heading-line"}>
                <span style={{fontFamily: "Manrope"}}><b>Информация о пользователе</b></span>
            </h5>
            <div>
                <form className="uk-form-horizontal">
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Логин:</label>
                        <div className="uk-form-controls">
                            <input autoFocus required className="uk-input"
                                   style={{color: "black", fontSize: "15px"}} type="text"
                                   value={formState.login || ""} maxLength={255}
                                   onChange={(e) => {}}/>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-stacked-text">ФИО:</label>
                        <div className="uk-form-controls">
                            <input required className="uk-input"
                                   style={{color: "black", fontSize: "15px"}} type="text"
                                   value={formState.name || ""} maxLength={255}
                                   onChange={(e) => {}}/>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-stacked-text">
                            Должность:
                        </label>
                        <div className="uk-form-controls">
                            <input required className="uk-input"
                                   style={{color: "black", fontSize: "15px"}} type="text"
                                   value={formState.jobTitle || ""} maxLength={255}
                                   onChange={(e) => {}}/>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Телефон:</label>
                        <div className="uk-form-controls">
                            <input required className="uk-input"
                                   style={{color: "black", fontSize: "15px"}} type="text"
                                   value={formState.phone || ""} maxLength={255}
                                   onChange={(e) => {}}/>
                        </div>
                    </div>
                    <div className="uk-margin">
                        <label className="uk-form-label" htmlFor="form-stacked-text">Электронная почта:</label>
                        <div className="uk-form-controls">
                            <input required className="uk-input"
                                   style={{color: "black", fontSize: "15px"}} type="text"
                                   value={formState.email || ""} maxLength={255}
                                   onChange={(e) => {}}/>
                        </div>
                    </div>
                </form>
                <button className="uk-button uk-button-primary uk-margin-right" type="button" onClick={saveHandler}>
                    Сохранить
                </button>
                <button className="uk-button uk-button-danger" type="button" onClick={changePassHandler}>
                    Смена пароля
                </button>
                <div>
                    <div ref={uiSaveDialogRef} className="uk-flex-top" data-uk-modal>
                        <div className={"uk-modal-dialog uk-margin-auto-vertical"}>
                            <div className={"uk-modal-body"}>
                                <form className="uk-form-horizontal">
                                    <div>
                                        <label className="uk-form-label" htmlFor="form-stacked-text">
                                            Пароль:
                                        </label>
                                        <div className="uk-form-controls">
                                            <input autoFocus required className="uk-input"
                                                   style={{color: "black", fontSize: "15px"}} type="text"
                                                   maxLength={255}
                                                   onChange={(e) => {}}/>
                                        </div>
                                    </div>
                                </form>
                                <div className={'uk-alert uk-alert-primary'}
                                     style={{fontSize: "14px"}}>Для обновления данных пользователя введите, пожалуйста, текущий пароль.
                                </div>
                            </div>
                            <div className="uk-text-right uk-modal-footer">
                                <button className="uk-button uk-button-default uk-modal-close uk-margin-right"
                                        type="button">
                                    Отмена
                                </button>
                                <button className="uk-button uk-button-primary uk-modal-close"
                                        type="button">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                    <div ref={uiChangePassDialogRef} className="uk-flex-top" data-uk-modal>
                        <div className={"uk-modal-dialog uk-margin-auto-vertical"}>
                            <div className={"uk-modal-body"}>
                                <form className="uk-form-horizontal">
                                    <div className="uk-margin">
                                        <label className="uk-form-label" htmlFor="form-stacked-text">
                                            Текущий пароль:
                                        </label>
                                        <div className="uk-form-controls">
                                            <input autoFocus required className="uk-input"
                                                   style={{color: "black", fontSize: "15px"}} type="password"
                                                   maxLength={255}
                                                   onChange={(e) => {}}/>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="uk-margin">
                                        <label className="uk-form-label" htmlFor="form-stacked-text">
                                            Новый пароль:
                                        </label>
                                        <div className="uk-form-controls">
                                            <input autoFocus required className="uk-input"
                                                   style={{color: "black", fontSize: "15px"}} type="password"
                                                   maxLength={255}
                                                   onChange={(e) => {}}/>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="uk-form-label" htmlFor="form-stacked-text">
                                            Подтверждение пароля:
                                        </label>
                                        <div className="uk-form-controls">
                                            <input autoFocus required className="uk-input"
                                                   style={{color: "black", fontSize: "15px"}} type="password"
                                                   maxLength={255}
                                                   onChange={(e) => {}}/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="uk-text-right uk-modal-footer">
                                <button className="uk-button uk-button-default uk-modal-close uk-margin-right"
                                        type="button">
                                    Отмена
                                </button>
                                <button className="uk-button uk-button-danger uk-modal-close"
                                        type="button">
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}


// eof
